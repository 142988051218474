import styled from '@emotion/styled';
import { Text } from '@shared/ui/typography';
import { breakpoints, prefixCls } from '@shared/theme';

export const AuthorName = styled(Text, {
  shouldForwardProp: (prop) => !['drawerTheme'].includes(prop),
})(({ drawerTheme }: { drawerTheme?: 'dark' | 'light' }) => ({
  [`&.${prefixCls}-typography.${prefixCls}-typography-secondary`]: {
    gap: '.4rem',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    color: drawerTheme === 'dark' ? '#FFFFFF73' : void 0,
    [`@media (max-width: ${breakpoints.md})`]: {
      fontSize: 10,
    },
  },
}));
