'use client';

import React from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '@shared/theme';
import {
  useDispatch,
  useSelector,
  onShowDrawer,
  selectAllLayout,
} from '@shared/redux';

const Grid1 = styled('div')(() => ({
  display: 'flex',
  gridArea: 'grid-1',
}));

const Grid2 = styled('div')(() => ({
  display: 'flex',
  gridArea: 'grid-2',
}));

const Grid3 = styled('div')(() => ({
  display: 'flex',
  gridArea: 'grid-3',
}));

const Grid4 = styled('div')(() => ({
  display: 'flex',
  gridArea: 'grid-4',
  [`@media (max-width: ${breakpoints.lg})`]: {
    display: 'none',
  },
}));

const Grid5 = styled('div')(() => ({
  display: 'flex',
  gridArea: 'grid-5',
  [`@media (max-width: ${breakpoints.lg})`]: {
    display: 'none',
  },
}));

const Grid6 = styled('div')(() => ({
  display: 'flex',
  gridArea: 'grid-6',
  [`@media (max-width: ${breakpoints.lg})`]: {
    display: 'none',
  },
}));

const xsGta = `'grid-1 grid-2' 'grid-3 grid-3'`;

const gta = `'grid-1 grid-1 grid-2 grid-3' 'grid-4 grid-5 grid-5 grid-6'`;

const EmptyStateContainer = styled('div')(() => ({
  gap: '2.4rem',
  display: 'grid',
  gridTemplateAreas: gta,
  gridTemplateRows: '364px 182px',
  '& > div': {
    cursor: 'pointer',
    borderRadius: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(22px)',
    outline: '2px dashed #FFFFFF',
    transition: 'background 0.4s ease',
    background: 'rgba(255, 255, 255, 0.20)',
    '&::after': {
      content: "' '",
      width: '3.3rem',
      height: '3.2rem',
      backgroundImage: "url('/images/plusOutlined.svg')",
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.5)',
    },
  },
  [`@media (max-width: ${breakpoints.lg})`]: {
    gridTemplateAreas: xsGta,
    gridTemplateRows: '175px 175px',
  },
}));

const XSEmptyStateContainer = styled('div')(() => ({
  gap: '2.4rem',
  display: 'grid',
  marginBottom: '2.4rem',
  gridTemplateAreas: xsGta,
  gridTemplateRows: '175px 175px',
  '& > div': {
    borderRadius: '24px',
    backdropFilter: 'blur(22px)',
    outline: '2px dashed #FFFFFF',
    background: 'rgba(255, 255, 255, 0.20)',
  },
}));

export const EmptyStateProvider: React.FC<
  React.PropsWithChildren<{
    shouldFetch?: boolean;
    previewMode: 'mobile' | 'desktop';
  }>
> = ({ children, previewMode, shouldFetch }) => {
  const dispatch = useDispatch();
  const allLayouts = useSelector(selectAllLayout);

  const isEmpty = !allLayouts.sm.length && !allLayouts.xxs.length;

  const openDrawer = () => dispatch(onShowDrawer());

  const isMobile = previewMode === 'mobile';

  if (shouldFetch) {
    return children;
  }

  return isEmpty ? (
    isMobile ? (
      <XSEmptyStateContainer>
        <Grid1 onClick={openDrawer} />
        <Grid2 onClick={openDrawer} />
        <Grid3 onClick={openDrawer} />
      </XSEmptyStateContainer>
    ) : (
      <EmptyStateContainer>
        <Grid1 onClick={openDrawer} />
        <Grid2 onClick={openDrawer} />
        <Grid3 onClick={openDrawer} />
        <Grid4 onClick={openDrawer} />
        <Grid5 onClick={openDrawer} />
        <Grid6 onClick={openDrawer} />
      </EmptyStateContainer>
    )
  ) : (
    children
  );
};
