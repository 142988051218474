'use client';

import React from 'react';
import { DrawerProps } from 'antd';
import { DrawerBody } from './components/Body';
import { MkitDrawer } from '@shared/ui/MkitDrawer';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { FloatActions } from '@widgets/float-actions/ui/float-actions';
import {
  useDispatch,
  useSelector,
  onCloseDrawer,
  selectShouldFetch,
  selectDrawerTheme,
  selectDrawerIsOpen,
} from '@shared/redux';

const drawerStyles: DrawerProps['styles'] = {
  body: {
    height: '100%',
    display: 'flex',
    paddingInline: 0,
    flexDirection: 'column',
  },
  mask: { backgroundColor: 'transparent' },
};

export const ComponentsDrawer: React.FC<{
  slug: string;
  username: string;
}> = ({ slug, username }) => {
  const dispatch = useDispatch();
  const matches = useBreakpoint(991);
  const drawerTheme = useSelector(selectDrawerTheme);
  const shouldFetch = useSelector(selectShouldFetch);
  const drawerIsOpen = useSelector(selectDrawerIsOpen);

  const onClose = () => dispatch(onCloseDrawer());

  const afterOpenChange = (open: boolean) => {
    if (!open) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  return (
    <>
      {!shouldFetch && <FloatActions slug={slug} />}
      <MkitDrawer
        destroyOnClose
        onClose={onClose}
        open={drawerIsOpen}
        title="Add Component"
        id="componentsDrawer"
        styles={drawerStyles}
        mkitTheme={drawerTheme}
        paddingXS="0 1rem 2rem 1rem"
        padding="0 2.2rem 2rem 2.2rem"
        afterOpenChange={afterOpenChange}
        width={!matches ? 396 : undefined}
        size={matches ? 'large' : undefined}
        height={matches ? '88vh' : undefined}
        placement={matches ? 'bottom' : 'right'}
        classNames={{ wrapper: 'drawer-wrapper' }}
      >
        <DrawerBody slug={slug} username={username} />
      </MkitDrawer>
    </>
  );
};
