import React from 'react';
import { Box } from '@shared/ui/box';
import VirtualList from 'rc-virtual-list';
import { sleep } from '@shared/utils/sleep';
import { Empty, Skeleton, Tag } from 'antd';
import { Carousel } from '@shared/ui/Carousel';
import { ShuffleButton } from './shuffle-button';
import { mockData } from '../constants/mock-data';
import { useFetchThemeColorPalettesMutation } from '@shared/services/mediakit';
import { virtualListWrapperStyles } from '@widgets/mediakit/theme-drawer/ui/utils';
import {
  useDispatch,
  useSelector,
  onUpdateTheme,
  selectTagsArray,
  selectDrawerTheme,
  selectMediakitTheme,
} from '@shared/redux';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { breakpoints } from '@shared/theme';

export const PaletteTags: React.FC<{
  currentColor: any;
  currentColorId: number;
}> = ({ currentColor, currentColorId }) => {
  const dispatch = useDispatch();
  const is768 = useBreakpoint(768);
  const tags = useSelector(selectTagsArray);
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const drawerTheme = useSelector(selectDrawerTheme);
  const [fetch] = useFetchThemeColorPalettesMutation();
  const mediakitTheme = useSelector(selectMediakitTheme);
  const [selectedTag, setSelectedTag] = React.useState<number>(0);
  const [virtualListHeight, setVirtualListHeight] = React.useState(0);
  const [params, setParams] = React.useState<any>({
    page: 1,
    limit: 50,
    offset: 0,
    tag: undefined,
    color: currentColorId,
  });

  const boxRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (boxRef.current) {
        const myObserver = new ResizeObserver((entries) => {
          entries.forEach((entry) => {
            setVirtualListHeight(entry.contentRect.height);
          });
        });

        myObserver.observe(boxRef.current);
      }
    }, 1000);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (boxRef.current) {
        setVirtualListHeight(boxRef.current.clientHeight);
      }
    }, 1000);
  }, []);

  React.useEffect(() => {
    async function call() {
      setLoading(true);

      await sleep(400);

      const res = await fetch({
        tag: params.tag,
        limit: params.limit,
        color: params.color,
        offset: params.offset,
      }).unwrap();

      const tmp = (res?.data?.palettes || []).reduce(
        (acc: any, curr: any, index: number) => {
          if (index % 2 === 0 && index !== 0) {
            acc.nestedIndex++;
          }

          if (!acc.arr[acc.nestedIndex]) {
            acc.arr[acc.nestedIndex] = [curr];
          } else {
            acc.arr[acc.nestedIndex].push(curr);
          }

          return acc;
        },
        {
          arr: [],
          nestedIndex: 0,
        },
      );

      setData((prev) => {
        prev.splice(-4);

        return [...prev, ...tmp.arr];
      });

      setLoading(false);
    }

    call().catch(console.error);
  }, [fetch, params]);

  const onScroll = async (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          virtualListHeight,
      ) <= 1
    ) {
      if (!loading) {
        setParams({
          ...params,
          color: currentColorId,
          offset: params.offset + params.limit,
          tag: selectedTag === 0 ? void 0 : selectedTag,
        });
      }
    }
  };

  React.useEffect(() => {
    if (loading) {
      setData((prev) => {
        return [...prev, ...mockData.arr];
      });
    }
  }, [loading]);

  const justify = ['flex-end', 'center', 'flex-start'];

  return (
    <>
      {!is768 ? (
        <Carousel size="small">
          {tags?.map((tag, index) => {
            let style: React.CSSProperties = {
              margin: 5,
              borderRadius: 8,
              transition: 'none',
              padding: '5px 12px',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(0, 0, 0, 0.02)',
              border: '3px solid rgba(0, 0, 0, 0.15)',
            };

            if (index === 0) {
              style = { ...style, margin: 5, marginLeft: 0 };
            } else if (index === tags.length - 1) {
              style = { ...style, margin: 5, marginRight: 0 };
            }

            const checked = tag.id === selectedTag;

            if (drawerTheme === 'dark') {
              style = {
                ...style,
                color: '#fff',
                background: 'rgba(255, 255, 255, 0.02)',
                border: '1px solid rgba(255, 255, 255, 0.15)',
              };
            }

            if (checked) {
              style = {
                ...style,
                fontWeight: 700,
                color: '#000000E0',
                background: '#fff',
                border: '3px solid #5956E9',
              };
            } else {
              style = {
                ...style,
                padding: '7px 12px',
              };
            }

            return (
              <div key={index}>
                <div>
                  <Tag.CheckableTag
                    key={tag.id}
                    style={style}
                    checked={checked}
                    onClick={() => {
                      if (!loading) {
                        setData([]);

                        if (tag.id !== selectedTag) {
                          setParams({
                            ...params,
                            offset: 0,
                            tag: tag.id,
                          });
                        } else {
                          setParams({
                            ...params,
                            offset: 0,
                            tag: void 0,
                          });
                        }

                        setSelectedTag((prevState) => {
                          if (prevState === tag.id) {
                            return 0;
                          }

                          return tag.id;
                        });
                      }
                    }}
                  >
                    {tag.name}
                  </Tag.CheckableTag>
                </div>
              </div>
            );
          })}
        </Carousel>
      ) : (
        <Box
          boxStyles={{
            height: 55,
            width: '100%',
            overflowX: 'scroll',
            overflowY: 'hidden',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar:horizontal': {
              display: 'none',
            },
          }}
        >
          {tags?.map((tag, index) => {
            let style: React.CSSProperties = {
              margin: 5,
              borderRadius: 8,
              transition: 'none',
              padding: '5px 12px',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(0, 0, 0, 0.02)',
              border: '3px solid rgba(0, 0, 0, 0.15)',
            };

            if (index === 0) {
              style = { ...style, margin: 5, marginLeft: 0 };
            } else if (index === tags.length - 1) {
              style = { ...style, margin: 5, marginRight: 0 };
            }

            const checked = tag.id === selectedTag;

            if (drawerTheme === 'dark') {
              style = {
                ...style,
                color: '#fff',
                background: 'rgba(255, 255, 255, 0.02)',
                border: '1px solid rgba(255, 255, 255, 0.15)',
              };
            }

            if (checked) {
              style = {
                ...style,
                fontWeight: 700,
                color: '#000000E0',
                background: '#fff',
                border: '3px solid #5956E9',
              };
            } else {
              style = {
                ...style,
                padding: '7px 12px',
              };
            }

            return (
              <div key={index}>
                <div>
                  <Tag.CheckableTag
                    key={tag.id}
                    style={style}
                    checked={checked}
                    onClick={() => {
                      if (!loading) {
                        setData([]);

                        if (tag.id !== selectedTag) {
                          setParams({
                            ...params,
                            offset: 0,
                            tag: tag.id,
                          });
                        } else {
                          setParams({
                            ...params,
                            offset: 0,
                            tag: void 0,
                          });
                        }

                        setSelectedTag((prevState) => {
                          if (prevState === tag.id) {
                            return 0;
                          }

                          return tag.id;
                        });
                      }
                    }}
                  >
                    {tag.name}
                  </Tag.CheckableTag>
                </div>
              </div>
            );
          })}
        </Box>
      )}

      <Box ref={boxRef} boxStyles={virtualListWrapperStyles}>
        {data.length === 0 && !loading ? (
          <Empty />
        ) : (
          <VirtualList
            data={data}
            itemHeight={124}
            onScroll={onScroll}
            height={virtualListHeight}
            itemKey={(item: any) => item[0].id || item[0]}
            styles={{
              verticalScrollBar: {
                width: 5,
                backgroundColor: '#f1f1f1',
                display: is768 ? 'none' : void 0,
              },
              horizontalScrollBarThumb: {
                backgroundColor: '#888',
                display: is768 ? 'none' : void 0,
              },
            }}
          >
            {(color: any, index: any) => {
              return (
                <Box
                  key={index}
                  boxStyles={{
                    gap: '1.6rem',
                    display: 'grid',
                    justifyContent: 'center',
                    gridTemplateColumns: 'repeat(2, 170px)',
                  }}
                >
                  {color.map((co: any, index: number) => {
                    let className = '';
                    const colors = co?.codes?.split(',');
                    let border = '3px solid rgba(255, 255, 255, 0.20)';
                    const selected = co?.id === mediakitTheme?.palette_id;

                    if (selected) {
                      className = 'selected-palette';
                      border = `3px solid ${currentColor.code}`;
                    }

                    let background;
                    if (drawerTheme === 'dark') {
                      background =
                        'linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(255, 255, 255, 0.16) 37%, rgba(190, 190, 190, 0.2) 63%)';
                    }

                    return (
                      <div
                        data-id={co.id || co}
                        key={`a-${index}`}
                        style={{
                          display: 'flex',
                          justifyContent: justify[index],
                        }}
                      >
                        <Box>
                          {!co?.id ? (
                            <Box
                              boxStyles={{
                                border,
                                width: 170,
                                height: 170,
                                minWidth: 170,
                                borderRadius: 10,
                                marginBottom: 15,
                                cursor: 'pointer',
                                overflow: 'hidden',
                                position: 'relative',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Skeleton.Avatar
                                active
                                size={170}
                                shape="square"
                                style={{ background }}
                              />
                            </Box>
                          ) : (
                            <Box
                              onClick={() => {
                                if (!className) {
                                  dispatch(
                                    onUpdateTheme({
                                      palette: co.codes,
                                      palette_id: co.id,
                                      shuffled_palette: co.codes,
                                      primary_color: currentColor.code,
                                    }),
                                  );
                                }
                              }}
                              className={className}
                              boxStyles={{
                                border,
                                padding: 8,
                                width: 170,
                                height: 170,
                                minWidth: 170,
                                borderRadius: 10,
                                marginBottom: 15,
                                cursor: 'pointer',
                                position: 'relative',
                                background: selected ? '#F5F5F5' : '#001529',
                                '&:hover > .selected-palette-shuffle-icon': {
                                  opacity: 1,
                                },
                                '&.selected-palette': {
                                  cursor: 'initial',
                                },
                              }}
                            >
                              {selected && <ShuffleButton />}
                              {colors.map((c: string, index: number) => {
                                return (
                                  <Box
                                    key={index}
                                    boxStyles={{
                                      width: '25%',
                                      backgroundColor: c,
                                    }}
                                  />
                                );
                              })}
                            </Box>
                          )}
                        </Box>
                      </div>
                    );
                  })}
                </Box>
              );
            }}
          </VirtualList>
        )}
      </Box>
    </>
  );
};
