import React from 'react';
import debounce from 'lodash.debounce';
import { prefixCls } from '@shared/theme';
import { Select, Skeleton, Tag } from 'antd';
import { PlusOutlined } from '@shared/ui/Icons';
import { Categories } from '@shared/types/categories';
import styled, { CSSObject } from '@emotion/styled';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  useSelector,
  selectCategories,
  selectMediakitTheme,
  selectSelectedCategories,
} from '@shared/redux';

const tagPlusStyle: React.CSSProperties = {
  gap: 4,
  margin: 0,
  height: 32,
  display: 'flex',
  borderRadius: 8,
  fontSize: '1.4rem',
  alignItems: 'center',
  background: 'transparent',
  border: '1px dashed rgba(0, 0, 0, 0.15)',
};

const StyledSelect = styled(Select, {
  shouldForwardProp: (propName) => propName !== 'selectSelector',
})<{ selectSelector?: CSSObject }>(({ selectSelector }) => ({
  [`&.${prefixCls}-select`]: {
    [`.${prefixCls}-select-selector`]: {
      ...selectSelector,
    },
  },
  '& input': {
    maxWidth: '100% !important',
  },
}));

const CategorySkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    width: 86,
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 32,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 32,
        marginBottom: 0,
        borderRadius: 99,
      },
    },
  },
}));

export const AddNewTag = React.forwardRef(
  (
    {
      onBlur,
      onSelect,
      disabled,
      showInput,
      inputVisible,
      showSkeleton,
    }: {
      disabled?: boolean;
      onBlur: () => void;
      showInput: () => void;
      inputVisible: boolean;
      showSkeleton?: boolean;
      onSelect: (
        _value: Categories.Category['value'],
        option?: Categories.Category,
      ) => void;
    },
    ref,
  ) => {
    const posthog = useLocalPostHog();
    const theme = useSelector(selectMediakitTheme);
    const categories = useSelector(selectCategories);
    const selected = useSelector(selectSelectedCategories);

    if (selected.length >= 5) {
      return null;
    }

    const selectedValues = selected?.map((s) => Number(s.value));

    const options = categories.filter((cat) => {
      return !selectedValues.includes(cat.value);
    });

    const filterOption = (
      input: string,
      option?: { label: string; value: string },
    ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
      e.currentTarget.classList.remove('animate__shakeY');
    };

    const debouncedOnInputKeyDown = debounce((value: string) => {
      try {
        posthog?.capture(PosthogEvents.CategorySearch, {
          category: value,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }, 400);

    return (
      <>
        <StyledSelect
          autoFocus
          value={[]}
          showSearch
          mode="multiple"
          onBlur={onBlur}
          ref={ref as any}
          options={options}
          open={inputVisible}
          onSelect={onSelect as any}
          style={{
            width: '100%',
            textAlign: 'left',
            display: inputVisible ? 'block' : 'none',
          }}
          selectSelector={{
            borderColor: `${theme?.primary_color} !important` || void 0,
          }}
          filterOption={filterOption as any}
          placeholder="Type category name here"
          onInputKeyDown={(e) => {
            if (e.currentTarget.value) {
              debouncedOnInputKeyDown(e.currentTarget.value);
            }
          }}
        />
        {showSkeleton ? (
          <CategorySkeleton
            paragraph={false}
            active={showSkeleton}
            loading={showSkeleton}
          />
        ) : (
          <Tag
            style={{
              ...tagPlusStyle,
              display: !inputVisible ? 'flex' : 'none',
            }}
            icon={<PlusOutlined />}
            onAnimationEnd={onAnimationEnd}
            onClick={!disabled ? showInput : undefined}
            className={`${fontVariants.opt3} animate__animated`}
            id={!disabled ? 'categoriesSelectTarget' : undefined}
          >
            Category
          </Tag>
        )}
      </>
    );
  },
);

AddNewTag.displayName = 'AddNewTag';
