import React from 'react';
import { MenuProps } from 'antd/es/menu';
import { prefixCls } from '@shared/theme';
import { DownOutlined } from '@shared/ui/Icons';
import { hexToRgb } from '@shared/utils/hexToRgb';
import { Button, Dropdown, Skeleton } from 'antd';
import styled, { CSSObject } from '@emotion/styled';
import { defaultEndColor } from '@shared/constants';
import { CtaLabels } from '@shared/constants/ctaLabels';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { textContrastChecker } from '@shared/utils/textContrastChecker';
import {
  useDispatch,
  useSelector,
  onChangeCtaText,
  selectActionButton,
  selectMediakitTheme,
} from '@shared/redux';

const menuProps: MenuProps['items'] = [
  {
    key: CtaLabels['Partner with me'],
    label: CtaLabels['Partner with me'],
  },
  {
    key: CtaLabels['Work with me'],
    label: CtaLabels['Work with me'],
  },
  {
    key: CtaLabels['Get in touch'],
    label: CtaLabels['Get in touch'],
  },
  {
    key: CtaLabels['Contact for collabs'],
    label: CtaLabels['Contact for collabs'],
  },
  {
    key: CtaLabels["Let's team up"],
    label: CtaLabels["Let's team up"],
  },
];

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => {
    return !['color', 'backgroundColor'].includes(propName);
  },
})<{
  color?: CSSObject['color'];
  backgroundColor?: CSSObject['backgroundColor'];
}>(({ color, backgroundColor }) => ({
  position: 'relative',
  [`&.${prefixCls}-btn-primary:not(:disabled):not(.${prefixCls}-btn-disabled)`]:
    {
      color,
      backgroundColor,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: `${backgroundColor}E0`,
      },
    },
}));

const Div = styled('div', {
  shouldForwardProp: (propName) => {
    return !['color', 'backgroundColor'].includes(propName);
  },
})<{
  color?: CSSObject['color'];
  backgroundColor?: CSSObject['backgroundColor'];
}>(({ color, backgroundColor }) => ({
  [`& > .${prefixCls}-dropdown .${prefixCls}-dropdown-menu .${prefixCls}-dropdown-menu-item-selected`]:
    {
      color,
      backgroundColor,
    },
}));

const ActionContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
}));

const IconContainer = styled('div')(() => ({
  top: 0,
  right: 0,
  bottom: 0,
  marginRight: 9,
  display: 'flex',
  color: '#FFFFFFA6',
  marginBlock: 'auto',
  position: 'absolute',
  alignItems: 'center',
}));

export const ActionButton: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const dispatch = useDispatch();
  const ref = React.useRef<any>();
  const posthog = useLocalPostHog();
  const value = useSelector(selectActionButton);
  const theme = useSelector(selectMediakitTheme);

  const primaryColor = theme?.primary_color || defaultEndColor;

  const { r, g, b } = hexToRgb(primaryColor);
  const buttonTextColor = textContrastChecker(r, g, b);

  const onSelect: MenuProps['onSelect'] = (e) => {
    dispatch(onChangeCtaText(e.selectedKeys[0]));

    try {
      posthog?.capture(PosthogEvents.PartnerBtnClicked, {
        text: e.selectedKeys[0],
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <ActionContainer>
      <Div ref={ref} color={buttonTextColor} backgroundColor={primaryColor} />
      {showSkeleton ? (
        <Skeleton.Button block size="large" />
      ) : (
        <Dropdown
          placement="top"
          trigger={['click']}
          menu={{
            onSelect,
            items: menuProps,
            selectable: true,
            selectedKeys: [value],
          }}
          getPopupContainer={() => ref.current}
        >
          <StyledButton
            block
            size="large"
            type="primary"
            color={buttonTextColor as any}
            className={fontVariants.opt2}
            backgroundColor={primaryColor}
          >
            {value}
            <IconContainer>
              <DownOutlined fill={buttonTextColor} />
            </IconContainer>
          </StyledButton>
        </Dropdown>
      )}
    </ActionContainer>
  );
};
