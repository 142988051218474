import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { Text } from '@shared/ui/typography';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { AppRoutes } from '@shared/constants/appRoutes';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { useUpgradeModal } from '@shared/hooks/useUpgradeModal';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { selectAuthUserHandle, useSelector } from '@shared/redux';
import { useCheckInternetConnection } from '@shared/hooks/useCheckInternetConnection';
import {
  Menu,
  Flex,
  Button,
  Select,
  Popover,
  Skeleton,
  Typography,
} from 'antd';
import {
  HomeOutlined,
  PlusOutlined,
  RightOutlined,
  CheckOutlined,
} from '@ant-design/icons';

const HomeButtonContainer = styled('div')(() => ({
  height: 40,
  display: 'flex',
  borderRadius: 8,
  paddingInline: 8,
  alignItems: 'center',
  backgroundColor: 'white',
  boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
  '.desktop-menu': {
    display: 'flex',
  },
  '.mobile-menu': {
    display: 'none',
  },
  '@media (max-width: 768px)': {
    '.desktop-menu': {
      display: 'none',
    },
    '.mobile-menu': {
      display: 'flex',
    },
    paddingInline: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

const menuStyle = {
  maxWidth: 200,
  borderRight: 'none',
};

const selectStyle = {
  maxWidth: 200,
};

const dropdownStyle = {
  width: 200,
};

const overlayInnerStyle = {
  padding: 3,
};

const ButtonSkeleton = () => {
  const is768 = useBreakpoint(768);
  const style = {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: is768 ? 40 : 204,
    minWidth: is768 ? 'unset' : void 0,
  };

  return <Skeleton.Button active size="large" style={style} />;
};

export const HomeButton: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  useCheckInternetConnection();
  const posthog = useLocalPostHog();
  const { handleOpen } = useUpgradeModal();
  const handle = useSelector(selectAuthUserHandle);

  const options = [
    {
      label: handle,
      value: handle,
    },
  ];

  const content = (
    <Menu
      style={menuStyle}
      items={[
        {
          key: handle!,
          label: <Typography.Text>{handle}</Typography.Text>,
          icon: <CheckOutlined style={{ color: '#5956E9' }} />,
        },
        {
          key: 'divider',
          type: 'divider',
        },
        {
          key: 'back-to-home',
          icon: <HomeOutlined />,
          label: <Link href={AppRoutes.dashboard}>Back to Home</Link>,
          onClick: () => {
            try {
              posthog?.capture(PosthogEvents.HomeClicked);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          },
        },
        {
          key: 'create-new',
          label: (
            <Typography.Text
              style={{
                color: '#5956E9',
                fontSize: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              Create New
            </Typography.Text>
          ),
          onClick: () => {
            handleOpen('mediakit', {
              from: 'builder_create_new_button',
            });

            try {
              posthog?.capture(PosthogEvents.CreateNewClicked);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          },
          icon: <PlusOutlined style={{ color: '#5956E9' }} />,
        },
      ]}
      selectedKeys={[handle!]}
    />
  );

  const desktopContent = (
    <Menu
      style={menuStyle}
      items={[
        {
          key: handle!,
          label: <Typography.Text>{handle}</Typography.Text>,
          icon: <CheckOutlined style={{ color: '#5956E9' }} />,
        },
        {
          key: 'divider',
          type: 'divider',
        },
        {
          key: 'create-new',
          label: (
            <Typography.Text
              style={{
                color: '#5956E9',
                fontSize: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              Create New
            </Typography.Text>
          ),
          onClick: () => {
            handleOpen('mediakit', {
              from: 'builder_create_new_button',
            });

            try {
              posthog?.capture(PosthogEvents.CreateNewClicked);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          },
          icon: <PlusOutlined style={{ color: '#5956E9' }} />,
        },
      ]}
      selectedKeys={[handle!]}
    />
  );

  const mobileButtonOnClick = () => {
    try {
      posthog?.capture(PosthogEvents.HomeClicked);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const desktopButtonOnClick = () => {
    try {
      posthog?.capture(PosthogEvents.HomeClicked);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return showSkeleton ? (
    <ButtonSkeleton />
  ) : (
    <HomeButtonContainer>
      <Flex align="center" className="mobile-menu">
        <Popover
          content={content}
          trigger={['click']}
          placement="bottomLeft"
          overlayInnerStyle={overlayInnerStyle}
        >
          <Button
            size="large"
            icon={<HomeOutlined />}
            onClick={mobileButtonOnClick}
          />
        </Popover>
      </Flex>
      <Flex align="center" className="desktop-menu">
        <Link href={AppRoutes.dashboard} onClick={desktopButtonOnClick}>
          <Button
            type="text"
            icon={
              <Text type="secondary">
                <HomeOutlined />
              </Text>
            }
          />
        </Link>
        <Text type="secondary">
          <RightOutlined />
        </Text>
        <Popover
          trigger={['click']}
          placement="bottomLeft"
          content={desktopContent}
          overlayInnerStyle={overlayInnerStyle}
        >
          <Select
            size="small"
            open={false}
            value={handle}
            options={options}
            style={selectStyle}
            variant="borderless"
            dropdownStyle={dropdownStyle}
          />
        </Popover>
      </Flex>
    </HomeButtonContainer>
  );
};
