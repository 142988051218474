import React from 'react';
import { Typography } from 'antd';
import dynamic from 'next/dynamic';
import { Box } from '@shared/ui/box';
import { prefixCls } from '@shared/theme';
import { Builder } from '@shared/types/builder';
import { DemoChartCard } from '@shared/ui/demo-card';
import { largeSize } from '@shared/constants/builderCardSizes';
import { getTikTokAccountDetails } from '@widgets/mediakit/helpers';
import { selectIsFreePlan, useSelector } from '@shared/redux';

// @ts-ignore
const Bar = dynamic(() => import('@ant-design/plots').then((mod) => mod.Bar), {
  ssr: false,
});

const dummyData = [
  {
    sex: 'male',
    value: 5.3,
    age: '13-17',
  },
  {
    sex: 'female',
    age: '13-17',
    value: 1.2,
  },
  {
    sex: 'male',
    value: 44.9,
    age: '18-24',
  },
  {
    sex: 'female',
    age: '18-24',
    value: 7.6,
  },
  {
    sex: 'male',
    value: 28.2,
    age: '25-34',
  },
  {
    sex: 'female',
    age: '25-34',
    value: 3.7,
  },
  {
    sex: 'male',
    value: 6.6,
    age: '35-44',
  },
  {
    sex: 'female',
    age: '35-44',
    value: 0.2,
  },
  {
    sex: 'male',
    value: 2.2,
    age: '45-64',
  },
  {
    sex: 'female',
    age: '45-64',
    value: 0.1,
  },
  {
    sex: 'male',
    value: 0,
    age: '>65',
  },
  {
    sex: 'female',
    age: '>65',
    value: 0,
  },
];

export function TikTokSexAgeRatioDemo({
  data,
  size,
  onAddItem,
}: Builder.DemoComponentProps) {
  const isFree = useSelector(selectIsFreePlan);
  const detailsFunc = getTikTokAccountDetails()[data.name];

  const { title } = detailsFunc({});

  let localData = (data?.data || []).reduce((acc: any, curr: any) => {
    acc.push(
      {
        sex: 'male',
        value: Number((curr.male * 100).toFixed(1)),
        age: curr.code === '65-' ? '>65' : curr.code,
      },
      {
        sex: 'female',
        age: curr.code === '65-' ? '>65' : curr.code,
        value: Number((curr.female * 100).toFixed(1)),
      },
    );

    return acc;
  }, []);

  if (isFree) {
    localData = [...dummyData];
  }

  return (
    <DemoChartCard
      size={size}
      title={title}
      w={largeSize.w}
      h={largeSize.h}
      type={data.name}
      onClick={onAddItem}
    >
      <Box
        boxStyles={{
          gap: 8,
          padding: 16,
          paddingBottom: 0,
          alignItems: 'center',
          [`& .${prefixCls}-typography`]: {
            color: '#8C8C8C',
            fontSize: '0.6rem',
            lineHeight: '0.8rem',
          },
        }}
      >
        <Box
          boxStyles={{
            gap: 8,
            width: 'initial',
            alignItems: 'center',
          }}
        >
          <Box
            boxStyles={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: '#1677FF',
            }}
          />
          <Typography.Text>Male</Typography.Text>
        </Box>
        <Box
          boxStyles={{
            gap: 8,
            width: 'initial',
            alignItems: 'center',
          }}
        >
          <Box
            boxStyles={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: '#EB2F96',
            }}
          />
          <Typography.Text>Female</Typography.Text>
        </Box>
      </Box>
      <React.Suspense>
        <Bar
          autoFit
          xField="age"
          axis={{
            x: false,
            y: {
              tick: false,
              grid: true,
              line: false,
              label: false,
              title: false,
              gridLineWidth: 0.5,
              gridStroke: 'black',
              gridLineDash: [0, 0],
              gridStrokeOpacity: 0.15,
            },
          }}
          scale={{
            color: {
              palette: ['rgba(89, 126, 247, .85)', 'rgba(247, 89, 171, .85)'],
            },
          }}
          stack={false}
          yField="value"
          legend={false}
          tooltip={false}
          colorField="sex"
          seriesField="sex"
          data={{
            type: 'inline',
            value: localData || [],
          }}
          style={{
            radiusTopLeft: 10,
            radiusTopRight: 10,
          }}
        />
      </React.Suspense>
    </DemoChartCard>
  );
}
