import React from 'react';
import Image from 'next/image';
import { Box } from '@shared/ui/box';
import { sleep } from '@shared/utils/sleep';
import VirtualList from 'rc-virtual-list';
import { breakpoints } from '@shared/theme';
import { Builder } from '@shared/types/builder';
import { Empty, Input, Skeleton } from 'antd';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { BackgroundTypes } from '@shared/constants/background-types';
import { useThemeUnsplashBgImagesMutation } from '@shared/services/mediakit';
import { mockData } from '@widgets/mediakit/theme-drawer/constants/mock-data';
import {
  Card,
  imageStyles,
  ImageWrapper,
  wrapperStyles,
  searchWrapperStyles,
  virtualListWrapperStyles,
} from './utils';
import {
  useSelector,
  useDispatch,
  onUpdateTheme,
  selectThemeColors,
  selectDrawerTheme,
  selectMediakitTheme,
} from '@shared/redux';
import useBreakpoint from '@shared/hooks/useBreakpoint';

const boxStyles = {
  gap: '1.6rem',
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(2, 170px)',
  '& > div': {
    height: 170,
  },
};

export const UnsplashImages = () => {
  const dispatch = useDispatch();
  const is768 = useBreakpoint(768);
  const posthog = useLocalPostHog();
  const [search, setSearch] = React.useState('');
  const theme = useSelector(selectMediakitTheme);
  const boxRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = React.useState<any[]>([]);
  const [fetch] = useThemeUnsplashBgImagesMutation();
  const [loading, setLoading] = React.useState(true);
  const colorsObject = useSelector(selectThemeColors);
  const drawerTheme = useSelector(selectDrawerTheme);
  const [virtualListHeight, setVirtualListHeight] = React.useState(0);

  const [selectedBgImageId, setSelectedBgImageId] = React.useState<
    string | undefined
  >(`${theme?.bg_image_id}`);

  const [{ limit, page, q }, setParams] = React.useState({
    q: '',
    page: 1,
    limit: 24,
  });

  React.useEffect(() => {
    async function call() {
      if (page === 1) {
        setData([]);
      }

      setLoading(true);

      await sleep(400);
      const res = await fetch(`q=${q}&page=${page}&limit=${limit}`).unwrap();

      const tmp = (res?.data?.images || []).reduce(
        (acc: any, curr: any, index: number) => {
          if (index % 2 === 0 && index !== 0) {
            acc.nestedIndex++;
          }

          if (!acc.arr[acc.nestedIndex]) {
            acc.arr[acc.nestedIndex] = [curr];
          } else {
            acc.arr[acc.nestedIndex].push(curr);
          }

          return acc;
        },
        {
          arr: [],
          nestedIndex: 0,
        },
      );

      setData((prev) => {
        prev.splice(-4);

        return [...prev, ...tmp.arr];
      });

      setLoading(false);
    }

    call().catch(console.error);
  }, [fetch, page, limit, q]);

  React.useEffect(() => {
    setTimeout(() => {
      if (boxRef.current) {
        setVirtualListHeight(boxRef.current.clientHeight);
      }
    }, 1000);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (boxRef.current) {
        const myObserver = new ResizeObserver((entries) => {
          entries.forEach((entry) => {
            setVirtualListHeight(entry.contentRect.height);
          });
        });

        myObserver.observe(boxRef.current);
      }
    }, 1000);
  }, []);

  const onScroll = async (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          virtualListHeight,
      ) <= 1
    ) {
      if (!loading) {
        setParams((prev) => ({ ...prev, page: prev.page + 1 }));
      }
    }
  };

  React.useEffect(() => {
    if (loading) {
      setData((prev) => {
        return [...prev, ...mockData.arr];
      });
    }
  }, [loading]);

  let currentColor: Builder.Theme.Color;

  if (theme?.color_id) {
    currentColor = colorsObject[theme.color_id];
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (!e.target.value) {
      setParams((prev) => ({
        ...prev,
        page: 1,
        q: '',
      }));
    }
  };

  const onSearch = (value: string) => {
    if (value?.trim()) {
      setParams((prev) => ({
        ...prev,
        page: 1,
        q: value?.trim(),
      }));
    }
  };

  return (
    <Box boxStyles={wrapperStyles}>
      <Box boxStyles={searchWrapperStyles}>
        <Input.Search
          allowClear
          value={search}
          onChange={onChange}
          onSearch={onSearch}
          placeholder="Search photos, e.g. abstract"
        />
      </Box>
      <Box ref={boxRef} boxStyles={virtualListWrapperStyles}>
        {data.length === 0 && !loading ? (
          <Empty />
        ) : (
          <VirtualList
            data={data}
            itemHeight={170}
            onScroll={onScroll}
            styles={{
              verticalScrollBar: {
                width: 5,
                backgroundColor: '#f1f1f1',
                display: is768 ? 'none' : void 0,
              },
              horizontalScrollBarThumb: {
                backgroundColor: '#888',
                display: is768 ? 'none' : void 0,
              },
            }}
            height={virtualListHeight}
            itemKey={(item: any) => item[0].id || item[0]}
          >
            {(imageGroup: any, index: number) => {
              return (
                <Box key={index} boxStyles={boxStyles}>
                  {imageGroup.map((image: any, nestedIndex: number) => {
                    const selected = selectedBgImageId === image.id;

                    const onClick = () => {
                      if (!selected) {
                        dispatch(
                          onUpdateTheme({
                            bg_image: image.image,
                            bg_image_id: image.id,
                            bg_image_type: BackgroundTypes.Unsplash,
                          }),
                        );
                        setSelectedBgImageId(image.id);

                        try {
                          posthog?.capture(PosthogEvents.BackgroundType, {
                            type: 'unsplash',
                          });
                        } catch (e) {
                          console.error('Posthog error:', e);
                        }
                      }
                    };

                    let className = '';
                    let border = '3px solid rgba(0, 0, 0, 0.15)';

                    if (drawerTheme === 'dark') {
                      border = '3px solid rgba(255, 255, 255, 0.20)';
                    }

                    let backgroundColor: string | undefined;

                    if (selected) {
                      className = 'selected-palette';
                      border = `3px solid ${currentColor?.code}`;

                      if (drawerTheme === 'dark') {
                        backgroundColor = '#fff';
                      }
                    }

                    return (
                      <Card
                        mb="1.6rem"
                        border={border}
                        key={nestedIndex}
                        onClick={onClick}
                        className={className}
                        backgroundColor={backgroundColor}
                      >
                        {!image.thumb ? (
                          <Skeleton.Avatar active size={170} shape="square" />
                        ) : (
                          <ImageWrapper>
                            <Image
                              fill
                              quality={50}
                              sizes="100%"
                              src={image.thumb}
                              style={imageStyles}
                              alt="Background Image"
                            />
                          </ImageWrapper>
                        )}
                      </Card>
                    );
                  })}
                </Box>
              );
            }}
          </VirtualList>
        )}
      </Box>
    </Box>
  );
};
