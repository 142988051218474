import React from 'react';
import { Builder } from '@shared/types/builder';
import { ComponentNames } from '@shared/constants/componentNames';
import * as Demo from '@widgets/mediakit/components-drawer/components/DemoComponents/YouTube';

export const componentsMap: Record<
  string,
  (props: Builder.DemoComponentProps) => React.JSX.Element
> = {
  [ComponentNames.youtube_profile]: Demo.YouTubeProfileDemo,
  [ComponentNames.youtube_avg_views]: Demo.YouTubeAccountDemo,
  [ComponentNames.youtube_avg_likes]: Demo.YouTubeAccountDemo,
  [ComponentNames.youtube_followers]: Demo.YouTubeAccountDemo,
  [ComponentNames.youtube_avg_comments]: Demo.YouTubeAccountDemo,
  [ComponentNames.youtube_engagement_rate]: Demo.YouTubeAccountDemo,
  [ComponentNames.youtube_audience_genders]: Demo.YouTubeSexRatioDemo,
  [ComponentNames.youtube_top_countries]: Demo.YouTubeTopCountriesDemo,
  [ComponentNames.youtube_views_growth]: Demo.YouTubeViewsFollowersGrowthDemo,
  [ComponentNames.youtube_followers_growth]:
    Demo.YouTubeViewsFollowersGrowthDemo,
  [ComponentNames.youtube_audience_genders_per_age]:
    Demo.YouTubeSexAgeRatioDemo,
};
