import React from 'react';
import Image from 'next/image';
import { message, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { sleep } from '@shared/utils/sleep';
import { prefixCls } from '@shared/theme';
import { ReplaceIcon } from '@shared/ui/Icons';
import { allowedImages } from '@shared/constants';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { BackgroundTypes } from '@shared/constants/background-types';
import { useUploadBackgroundImageMutation } from '@shared/services/mediakit';
import {
  UploaderBody,
  StyledUpload,
  buttonStyles,
  ReplaceIconWrapper,
  StyledUploadWrapper,
} from './utils';
import {
  useDispatch,
  useSelector,
  onUpdateTheme,
  selectDrawerTheme,
  selectMediakitTheme,
} from '@shared/redux';

export const StaticImage: React.FC<{ slug: string; username: string }> = ({
  slug,
}) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const theme = useSelector(selectMediakitTheme);
  const [bgImage, setBgImage] = React.useState('');
  const drawerTheme = useSelector(selectDrawerTheme);
  const [upload] = useUploadBackgroundImageMutation();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (theme?.bg_image_type === BackgroundTypes.Static) {
      setBgImage(theme?.bg_image!);
    }
  }, [theme?.bg_image, theme?.bg_image_type]);

  const beforeUpload = (file: RcFile) => {
    const isAllowedFormat = allowedImages.includes(file.type);

    if (!isAllowedFormat) {
      message.error('You can only upload jpg/jpeg/png/webp file!').then();

      return false;
    }

    const isAllowedSize = file.size / 1024 / 1024 < 20;

    if (!isAllowedSize) {
      message.error('Image must smaller than 20MB!').then();

      return false;
    }

    return true;
  };

  const customRequest = async ({ file }: UploadRequestOption) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append('image', file);

    await sleep(400);

    const { data } = await upload({ slug, data: formData }).unwrap();

    dispatch(
      onUpdateTheme({
        bg_image: data?.image,
        bg_image_type: BackgroundTypes.Static,
        bg_image_id: BackgroundTypes.Static as any,
      }),
    );

    try {
      posthog?.capture(PosthogEvents.BackgroundType, {
        type: 'upload',
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }

    setIsLoading(false);
  };

  let textColor;
  let iconColor;

  if (drawerTheme === 'dark') {
    iconColor = '#FFFFFF73';
    textColor = 'rgba(255, 255, 255, 0.88)';
  }

  return (
    <StyledUploadWrapper>
      <Spin spinning={isLoading}>
        <StyledUpload
          name="image"
          maxCount={1}
          method="POST"
          multiple={false}
          disabled={isLoading}
          showUploadList={false}
          listType="picture-card"
          drawerTheme={drawerTheme}
          beforeUpload={beforeUpload}
          customRequest={customRequest}
          accept={allowedImages.join(',')}
        >
          <UploaderBody>
            {!!bgImage ? (
              <>
                <Image
                  fill
                  alt="avatar"
                  sizes="100%"
                  src={bgImage}
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                />
                <ReplaceIconWrapper className={`${prefixCls}-replace-icon`}>
                  <ReplaceIcon />
                </ReplaceIconWrapper>
              </>
            ) : (
              <button style={buttonStyles} type="button">
                {!isLoading ? (
                  <PlusOutlined style={{ color: iconColor }} />
                ) : (
                  <LoadingOutlined style={{ color: iconColor }} />
                )}
                <div style={{ marginTop: 8, color: textColor }}>Upload</div>
              </button>
            )}
          </UploaderBody>
        </StyledUpload>
      </Spin>
    </StyledUploadWrapper>
  );
};
