import React from 'react';
import Image from 'next/image';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { Button, Divider, Flex, Modal, Skeleton, Typography } from 'antd';
import { PosthogEvents } from '@shared/constants/posthog-events';
import c390 from '@shared/icons/c390f8c4e57dc493345e806fbd4d5da5.gif';
import {
  onChangeBuilderPreviewMode,
  selectBuilderPreviewIsMobile,
  useDispatch,
  useSelector,
} from '@shared/redux';

const buttonStyle: React.CSSProperties = {
  padding: 0,
  display: 'flex',
  alignItems: 'center',
};

const Container = styled('div')(() => ({
  display: 'flex',
  padding: '.8rem',
  alignItems: 'center',
  borderRadius: '.8rem',
  backgroundColor: 'white',
  [`& > .${prefixCls}-divider`]: {
    height: '100%',
    borderInlineStart: '1px solid rgba(0, 0, 0, 0.2)',
  },
}));

export const ViewToggle: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const dispatch = useDispatch();
  const b768 = useBreakpoint(768);
  const posthog = useLocalPostHog();
  let isMobile = useSelector(selectBuilderPreviewIsMobile);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (b768) {
      dispatch(onChangeBuilderPreviewMode('desktop'));
    }
  }, [b768, dispatch]);

  const setView = (view: 'desktop' | 'mobile') => {
    if (b768) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
      dispatch(onChangeBuilderPreviewMode(view));
    }

    try {
      posthog?.capture(PosthogEvents.SwitcherClicked, {
        [view]: true,
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  if (b768) {
    isMobile = true;
  }

  return b768 ? null : showSkeleton ? (
    <Skeleton.Button style={buttonStyle} active size="large" />
  ) : (
    <Container>
      <Button
        type="link"
        size="small"
        style={buttonStyle}
        onClick={() => setView('desktop')}
      >
        <svg
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity={0.1}
            fill={!isMobile ? '#5956E9' : 'black'}
            d="M4.25 19.625H27.75V6.625H4.25V19.625Z"
          />
          <path
            fill={!isMobile ? '#5956E9' : 'black'}
            d="M29 4.375H3C2.44687 4.375 2 4.82187 2 5.375V20.875C2 21.4281 2.44687 21.875 3 21.875H14.875V25.375H9.5C9.225 25.375 9 25.6 9 25.875V27.375C9 27.5125 9.1125 27.625 9.25 27.625H22.75C22.8875 27.625 23 27.5125 23 27.375V25.875C23 25.6 22.775 25.375 22.5 25.375H17.125V21.875H29C29.5531 21.875 30 21.4281 30 20.875V5.375C30 4.82187 29.5531 4.375 29 4.375ZM27.75 19.625H4.25V6.625H27.75V19.625Z"
          />
        </svg>
      </Button>
      <Divider type="vertical" />
      <Button
        type="link"
        size="small"
        style={buttonStyle}
        onClick={() => setView('mobile')}
      >
        <svg
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillOpacity={0.88}
            fill={isMobile ? '#5956E9' : 'black'}
            d="M23.25 1.9375H8.75C7.64688 1.9375 6.75 2.83438 6.75 3.9375V27.9375C6.75 29.0406 7.64688 29.9375 8.75 29.9375H23.25C24.3531 29.9375 25.25 29.0406 25.25 27.9375V3.9375C25.25 2.83438 24.3531 1.9375 23.25 1.9375ZM23 27.6875H9V4.1875H23V27.6875ZM14.75 24.5C14.75 24.8315 14.8817 25.1495 15.1161 25.3839C15.3505 25.6183 15.6685 25.75 16 25.75C16.3315 25.75 16.6495 25.6183 16.8839 25.3839C17.1183 25.1495 17.25 24.8315 17.25 24.5C17.25 24.1685 17.1183 23.8505 16.8839 23.6161C16.6495 23.3817 16.3315 23.25 16 23.25C15.6685 23.25 15.3505 23.3817 15.1161 23.6161C14.8817 23.8505 14.75 24.1685 14.75 24.5Z"
          />
        </svg>
      </Button>
      <Modal
        width={360}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <Flex align="center" justify="center">
            <Button onClick={() => setModalVisible(false)} type="primary">
              Ok
            </Button>
          </Flex>
        }
      >
        <Flex vertical align="center" justify="center">
          <Image src={c390} alt={''} />
        </Flex>
        <Typography.Text
          style={{
            display: 'flex',
            textAlign: 'center',
            marginTop: '1.6rem',
            marginBottom: '2.4rem',
            justifyContent: 'center',
          }}
        >
          Edit the desktop version on your <br />
          computer for a better experience.
        </Typography.Text>
      </Modal>
    </Container>
  );
};
