import React from 'react';
import { Button, Tag } from 'antd';
import { Box } from '@shared/ui/box';
import { Text } from '@shared/ui/typography';
import { Builder } from '@shared/types/builder';
import { Carousel } from '@shared/ui/Carousel';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { ThemeFilter } from '@shared/constants/themeFilter';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { SeeAll } from '@widgets/mediakit/drawer-components/see-all';
import {
  useDispatch,
  useSelector,
  onUpdateTheme,
  selectThemeColors,
  selectDrawerTheme,
  selectThemeFilter,
  selectMediakitTheme,
  onChangeThemeFilter,
} from '@shared/redux';
import {
  HeadTitle,
  ButtonIcon,
  SeeAllContainer,
  DemoComponentsHead,
  DemoComponentsContainer,
} from '@shared/ui/demo-components-container';

export const AccentColor = ({ seeAll }: { seeAll?: boolean }) => {
  const colorsObject = useSelector(selectThemeColors);
  const colors = Object.values(colorsObject || {});

  return (
    <DemoComponentsContainer>
      <DemoComponentsHead>
        <Head count={colors.length} seeAll={seeAll} />
      </DemoComponentsHead>

      <Body seeAll={seeAll} />
    </DemoComponentsContainer>
  );
};

const Head: React.FC<{ count: number; seeAll?: boolean }> = ({
  count,
  seeAll,
}) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const drawerTheme = useSelector(selectDrawerTheme);

  const handleChangeFilter = (filter?: ThemeFilter) => () => {
    dispatch(onChangeThemeFilter(filter));

    if (filter) {
      try {
        posthog?.capture(PosthogEvents.SeeAllClicked, {
          section: 'Accent Color',
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  return seeAll ? (
    <HeadTitle
      strong
      type="secondary"
      cursor="pointer"
      drawerTheme={drawerTheme}
      onClick={handleChangeFilter(ThemeFilter.colors)}
    >
      <Button type="link" shape="circle" icon={<ButtonIcon />} />
      Accent Color
    </HeadTitle>
  ) : (
    <>
      <HeadTitle drawerTheme={drawerTheme} strong type="secondary">
        Accent Color
      </HeadTitle>
      <SeeAll
        count={count}
        onClick={handleChangeFilter(ThemeFilter.allAccentColor)}
      />
    </>
  );
};

const Body: React.FC<{ seeAll?: boolean }> = ({ seeAll }) => {
  const dispatch = useDispatch();
  const is768 = useBreakpoint(768);
  const themeFilter = useSelector(selectThemeFilter);
  const drawerTheme = useSelector(selectDrawerTheme);
  const colorsObject = useSelector(selectThemeColors);
  const mediakitTheme = useSelector(selectMediakitTheme);

  const [selectedColorId, setSelectedColorId] = React.useState(0);

  const colors = Object.values<Builder.Theme.Color>(colorsObject || {});

  React.useEffect(() => {
    if (mediakitTheme?.color_id) {
      setSelectedColorId(mediakitTheme.color_id);
    }

    return function () {
      setSelectedColorId(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeFilter]);

  const compareFn = (a: Builder.Theme.Color) => {
    if (a.id === selectedColorId) {
      return -1;
    }

    return 0;
  };

  return seeAll ? (
    <SeeAllContainer>
      {colors.sort(compareFn).map((color) => {
        const selected = mediakitTheme?.color_id === color.id;

        let props: React.SVGProps<SVGSVGElement> = {};

        if (['White', 'Grey'].includes(color.name)) {
          props = {
            strokeWidth: 1,
            stroke: 'rgba(0, 0, 0, 0.15)',
          };
        }

        let textColor;
        let backgroundColor;
        let border = '2px solid rgba(0, 0, 0, 0.15)';

        if (drawerTheme === 'dark') {
          textColor = '#fff';
          border = '2px solid rgba(0, 0, 0, 0.06)';
          backgroundColor = 'rgba(245, 245, 245, 0.20)';
        }

        if (selected) {
          border = `3px solid ${color.code}`;

          if (drawerTheme === 'dark') {
            textColor = void 0;
            backgroundColor = '#fff';
          }
        }

        return (
          <Box
            key={color.id}
            onClick={() => {
              if (mediakitTheme?.color_id !== color.id) {
                dispatch(
                  onUpdateTheme({
                    color_id: color.id,
                    primary_color: color.code,
                    palette: color.palettes[0].codes,
                    palette_id: color.palettes[0].palette_id,
                    shuffled_palette: color.palettes[0].codes,
                  }),
                );
              }
            }}
            boxStyles={{
              border,
              gap: 6,
              width: 65,
              height: 65,
              display: 'flex',
              backgroundColor,
              borderRadius: 8,
              cursor: 'pointer',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              textColor,
              fontWeight: selected ? 700 : 400,
            }}
          >
            <svg
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 14 14"
              xmlns="http://www.w3.org/2000/svg"
              {...props}
            >
              <circle cx="7" cy="7" r="7" fill={color.code} />
            </svg>
            <Text textProps={{ color: textColor }}>{color.name}</Text>
          </Box>
        );
      })}
    </SeeAllContainer>
  ) : !is768 ? (
    <div>
      <Carousel size="small">
        {colors.sort(compareFn).map((color, index) => {
          const selected = mediakitTheme?.color_id === color.id;

          let style: React.CSSProperties = {
            marginTop: 0,
            marginLeft: 4,
            marginRight: 4,
            borderRadius: 8,
            border: '1px solid rgba(255, 255, 255, 0.10)',
          };

          if (index === 0) {
            style = { marginTop: 0, marginRight: 4, marginLeft: 0 };
          } else if (index === colors.length - 1) {
            style = { marginTop: 0, marginLeft: 4, marginRight: 0 };
          }

          let props: React.SVGProps<SVGSVGElement> = {};
          if (['White', 'Grey'].includes(color.name)) {
            props = {
              strokeWidth: 1,
              stroke: 'rgba(0, 0, 0, 0.15)',
            };
          }

          let padding = '2px 8px';
          let border = '2px solid rgba(0, 0, 0, 0.06)';

          let textColor;
          let backgroundColor = 'rgba(245, 245, 245, 0.20)';

          if (drawerTheme === 'dark') {
            textColor = '#fff';
            border = '2px solid rgba(0, 0, 0, 0.06)';
            backgroundColor = 'rgba(245, 245, 245, 0.20)';
          }

          if (selected) {
            border = `3px solid ${color.code}`;
            padding = '2px 8px';

            if (drawerTheme === 'dark') {
              textColor = void 0;
              backgroundColor = '#fff';
            }
          }

          return (
            <div key={index}>
              <div style={style}>
                <Tag
                  key={color.id}
                  onClick={() => {
                    if (mediakitTheme?.color_id !== color.id) {
                      dispatch(
                        onUpdateTheme({
                          color_id: color.id,
                          primary_color: color.code,
                          palette: color.palettes[0].codes,
                          palette_id: color.palettes[0].palette_id,
                          shuffled_palette: color.palettes[0].codes,
                        }),
                      );
                    }
                  }}
                  style={{
                    border,
                    padding,
                    gap: 4,
                    margin: 0,
                    width: 65,
                    height: 65,
                    fontSize: 12,
                    backgroundColor,
                    borderRadius: 8,
                    color: textColor,
                    cursor: 'pointer',
                    lineHeight: '20px',
                    alignItems: 'center',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    fontWeight: selected ? 700 : 400,
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 14 14"
                    xmlns="http://www.w3.org/2000/svg"
                    {...props}
                  >
                    <circle r="7" cx="7" cy="7" fill={color.code} />
                  </svg>
                  {color.name}
                </Tag>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  ) : (
    <Box
      boxStyles={{
        width: '100%',
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar:horizontal': {
          display: 'none',
        },
      }}
    >
      {colors.sort(compareFn).map((color, index) => {
        const selected = mediakitTheme?.color_id === color.id;

        let style: React.CSSProperties = {
          marginTop: 0,
          marginLeft: 4,
          marginRight: 4,
          borderRadius: 8,
          border: '1px solid rgba(255, 255, 255, 0.10)',
        };

        if (index === 0) {
          style = { marginTop: 0, marginRight: 4, marginLeft: 0 };
        } else if (index === colors.length - 1) {
          style = { marginTop: 0, marginLeft: 4, marginRight: 0 };
        }

        let props: React.SVGProps<SVGSVGElement> = {};
        if (['White', 'Grey'].includes(color.name)) {
          props = {
            strokeWidth: 1,
            stroke: 'rgba(0, 0, 0, 0.15)',
          };
        }

        let padding = '2px 8px';
        let border = '2px solid rgba(0, 0, 0, 0.06)';

        let textColor;
        let backgroundColor = 'rgba(245, 245, 245, 0.20)';

        if (drawerTheme === 'dark') {
          textColor = '#fff';
          border = '2px solid rgba(0, 0, 0, 0.06)';
          backgroundColor = 'rgba(245, 245, 245, 0.20)';
        }

        if (selected) {
          border = `3px solid ${color.code}`;
          padding = '2px 8px';

          if (drawerTheme === 'dark') {
            textColor = void 0;
            backgroundColor = '#fff';
          }
        }

        return (
          <div key={index}>
            <div style={style}>
              <Tag
                key={color.id}
                onClick={() => {
                  if (mediakitTheme?.color_id !== color.id) {
                    dispatch(
                      onUpdateTheme({
                        color_id: color.id,
                        primary_color: color.code,
                        palette: color.palettes[0].codes,
                        palette_id: color.palettes[0].palette_id,
                        shuffled_palette: color.palettes[0].codes,
                      }),
                    );
                  }
                }}
                style={{
                  border,
                  padding,
                  gap: 4,
                  margin: 0,
                  width: 65,
                  height: 65,
                  fontSize: 12,
                  backgroundColor,
                  borderRadius: 8,
                  color: textColor,
                  cursor: 'pointer',
                  lineHeight: '20px',
                  alignItems: 'center',
                  display: 'inline-flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontWeight: selected ? 700 : 400,
                }}
              >
                <svg
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                  {...props}
                >
                  <circle r="7" cx="7" cy="7" fill={color.code} />
                </svg>
                {color.name}
              </Tag>
            </div>
          </div>
        );
      })}
    </Box>
  );
};
