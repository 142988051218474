import React from 'react';
import { Box } from '@shared/ui/box';
import { CSSObject } from '@emotion/styled';
import { breakpoints } from '@shared/theme';
import { BodyContainer } from '@shared/ui/body-container';
import { ThemeFilter } from '@shared/constants/themeFilter';
import { Head } from '@widgets/mediakit/theme-drawer/ui/head';
import { Fonts } from '@widgets/mediakit/theme-drawer/ui/fonts';
import { Colors } from '@widgets/mediakit/theme-drawer/ui/colors';
import { BackgroundTypes } from '@shared/constants/background-types';
import { Background } from '@widgets/mediakit/theme-drawer/ui/background';
import { AccentColor } from '@widgets/mediakit/theme-drawer/ui/accent-color';
import { ColorPalette } from '@widgets/mediakit/theme-drawer/ui/color-palette';
import {
  useSelector,
  selectThemeFilter,
  selectMediakitTheme,
} from '@shared/redux';

const boxStyles: CSSObject = {
  gap: '3.2rem',
  height: '100%',
  display: 'flex',
  overflowY: 'auto',
  flexDirection: 'column',
  paddingInline: '1.6rem',
  [`@media (max-width: ${breakpoints.md})`]: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingInline: '1rem',
  },
};

const Body: React.FC<{ slug: string; username: string }> = (props) => {
  const theme = useSelector(selectMediakitTheme);
  const selectedFilter = useSelector(selectThemeFilter);

  const [isUnsplash, setIsUnsplash] = React.useState<boolean>(
    theme?.bg_image_type === BackgroundTypes.Unsplash,
  );

  let height = '100%';

  if (isUnsplash && selectedFilter === ThemeFilter.allBackgrounds) {
    height = '100%';
  } else if (typeof selectedFilter === 'number') {
    height = '100%';
  }

  const filtersMapping: Record<
    ThemeFilter | number | 'default',
    React.ReactNode
  > = React.useMemo(() => {
    return {
      [ThemeFilter.colors]: <Colors />,
      [ThemeFilter.allFonts]: <Fonts {...props} />,
      [ThemeFilter.allAccentColor]: <AccentColor seeAll />,
      [ThemeFilter.allBackgrounds]: (
        <Background {...props} setIsUnsplash={setIsUnsplash} />
      ),
      default: <ColorPalette seeAll />,
    };
  }, [props]);

  const component = selectedFilter
    ? filtersMapping[selectedFilter] || filtersMapping.default
    : filtersMapping.default;

  return (
    <BodyContainer height={height}>
      <Head />
      <Box boxStyles={boxStyles}>{component}</Box>
    </BodyContainer>
  );
};

export const DrawerBody = React.memo(Body);
