import { Flex, Spin } from 'antd';
import styled from '@emotion/styled';
import { Builder } from '@shared/types/builder';
import { dynamicCards } from '@shared/constants';
import { Templates } from '@shared/types/templates';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { Layout as ILayout } from 'react-grid-layout';
import React, { Dispatch, SetStateAction } from 'react';
import { ComponentNames } from '@shared/constants/componentNames';
import { MediaKitServer } from '@widgets/mediakit/media-kit-server';
import { useFetchComponentsMutation } from '@shared/services/mediakit';
import { LayoutBreakpoints } from '@shared/constants/layoutBreakpoints';
import { useFetchTemplateMutation } from '@widgets/templates-modal/api';
import {
  onChangePreviewMode,
  selectCategories,
  selectPreviewMode,
  useDispatch,
  useSelector,
} from '@shared/redux';

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ isMobile }: { isMobile?: boolean }) => ({
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: 'calc(100vh - 144px)',
  width: isMobile ? 374 : '100%',
  marginInline: isMobile ? 'auto' : '0',
}));

export const PreviewModalContent: React.FC<{
  onCancel: () => void;
  previewModal: boolean;
  selectedTemplate: number;
  setTemplate: Dispatch<SetStateAction<number>>;
  onSuccess?: (template: Templates.Template) => void;
  setPreviewModal: Dispatch<SetStateAction<boolean>>;
}> = ({ onSuccess, selectedTemplate }) => {
  const dispatch = useDispatch();
  const matches991 = useBreakpoint(991);
  const categories = useSelector(selectCategories);
  const previewMode = useSelector(selectPreviewMode);
  const [fetchTemplate] = useFetchTemplateMutation();
  const [fetchComponents] = useFetchComponentsMutation();

  const [localTemplate, setLocalTemplate] = React.useState<
    Templates.Template | undefined
  >();

  React.useEffect(() => {
    dispatch(onChangePreviewMode(matches991 ? 'mobile' : 'desktop'));
  }, [matches991, dispatch]);

  React.useEffect(() => {
    return function () {
      dispatch(onChangePreviewMode(undefined));
    };
  }, [dispatch]);

  React.useEffect(() => {
    async function start() {
      const { data } = await fetchTemplate(selectedTemplate).unwrap();

      if (typeof onSuccess === 'function') {
        onSuccess(data.template);
      }

      setLocalTemplate(data.template);
    }

    start().then().catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTemplate, fetchComponents, selectedTemplate]);

  if (!localTemplate) {
    return (
      <Flex align="center" justify="center">
        <Spin size="large" />
      </Flex>
    );
  }

  const components = localTemplate.content.components;

  const { layouts, extraData } = components.reduce<{
    extraData: Builder.ExtraData;
    layouts: {
      [LayoutBreakpoints.sm]: ILayout[];
      [LayoutBreakpoints.xxs]: ILayout[];
    };
  }>(
    (acc, { mobile, desktop, uid, name, ...rest }) => {
      acc.layouts[LayoutBreakpoints.sm].push({ ...desktop, i: uid });
      acc.layouts[LayoutBreakpoints.xxs].push({ ...mobile, i: uid });

      if (dynamicCards.includes(name)) {
        if (name === ComponentNames.basics_service_list) {
          acc.extraData[uid] = rest.service_list || [];
        } else {
          acc.extraData[uid] = {
            ...rest,
            name,
          };
        }
      } else {
        acc.extraData[name] = {
          ...rest,
          name,
        };
      }

      return acc;
    },
    {
      layouts: {
        [LayoutBreakpoints.sm]: [],
        [LayoutBreakpoints.xxs]: [],
      },
      extraData: {},
    },
  );

  const isMobile = previewMode === 'mobile';

  return (
    <Container isMobile={isMobile}>
      <MediaKitServer
        isPreview
        layouts={layouts}
        extraData={extraData}
        categories={categories}
        mediakit={localTemplate as any}
        theme={localTemplate.content.theme}
      />
    </Container>
  );
};
