import React from 'react';
import styled from '@emotion/styled';
import { lightBoxShadow } from '../theme';
import { Tooltip, Typography } from 'antd';
import { DemoCardSize } from '@shared/constants';
import theme, { prefixCls } from '@shared/theme';
import { selectDrawerTheme, useSelector } from '@shared/redux';
import { CardHelper } from '@widgets/mediakit/components-drawer/components/CardHelper';

const { token } = theme;

interface CardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  w: number;
  h: number;
  type: string;
  title: string;
  platform: number;
  size?: DemoCardSize;
  comingSoon?: boolean;
  icon: React.ReactNode;
}

const CardContent = styled('div')({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const CardBody = styled('div', {
  shouldForwardProp: (propName) =>
    !['size', 'comingSoon', 'drawerTheme'].includes(propName),
})(({
  size,
  drawerTheme,
}: {
  size?: DemoCardSize;
  comingSoon?: boolean;
  drawerTheme?: 'dark' | 'light';
}) => {
  const color = drawerTheme === 'dark' ? '#fff' : '#1677FF';
  const bg = drawerTheme === 'dark' ? 'rgba(255, 255, 255, 0.20)' : '#fff';

  return {
    display: 'flex',
    cursor: 'pointer',
    padding: '1.2rem',
    backgroundColor: bg,
    alignItems: 'center',
    borderRadius: '.8rem',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: lightBoxShadow,
    width: size === DemoCardSize.large ? 104 : 90,
    height: size === DemoCardSize.large ? 104 : 90,
    border: `1px solid ${token!.colorBorderSecondary}`,
    [`& .${prefixCls}-typography`]: {
      color,
      display: 'flex',
      fontWeight: 600,
      fontSize: '3.2rem',
    },
  };
});

export const BasicDemoCard: React.FC<CardProps> = ({
  w,
  h,
  size,
  icon,
  type,
  title,
  onClick,
  platform,
  comingSoon,
}) => {
  const drawerTheme = useSelector(selectDrawerTheme);

  return (
    <CardContent
      data-w={w}
      data-h={h}
      data-type={type}
      onClick={onClick}
      data-platform={platform}
    >
      <Tooltip
        placement="bottom"
        trigger={['click']}
        title={comingSoon ? 'Coming Soon!' : null}
      >
        <CardBody size={size} comingSoon={comingSoon} drawerTheme={drawerTheme}>
          <Typography.Text>{icon}</Typography.Text>
        </CardBody>
      </Tooltip>
      <CardHelper size={size}>{title}</CardHelper>
    </CardContent>
  );
};
