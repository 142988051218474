'use client';

import React from 'react';
import Script from 'next/script';
import { useOnboardingMutation } from '@shared/services/auth';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  useSelector,
  selectAuthUser,
  selectCurrentMediakit,
} from '@shared/redux';

const formId = process.env.NEXT_PUBLIC_TALLY_FORM_ID;

export const Tally = () => {
  const posthog = useLocalPostHog();
  const user = useSelector(selectAuthUser);
  const [onboarding] = useOnboardingMutation();
  const mkit = useSelector(selectCurrentMediakit);
  const [loaded, setLoaded] = React.useState(false);

  const onLoad = () => {
    getTally()?.loadEmbeds();

    setLoaded(true);
  };

  const getTally = () => {
    if (typeof window === 'undefined') {
      return;
    }

    return (window as any).Tally;
  };

  React.useEffect(() => {
    if (!formId) {
      return;
    }

    const onClose = () => {
      onboarding({ step: 'show_survey' });

      try {
        posthog?.capture(PosthogEvents.TallyClosed, {
          email: user?.email,
          handle: user?.handle,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    const onSubmit = () => {
      onboarding({ step: 'show_survey' });

      try {
        posthog?.capture(PosthogEvents.TallyCompleted, {
          email: user?.email,
          handle: user?.handle,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    if (loaded && user?.email) {
      let handle = user?.handle;

      if (typeof window !== 'undefined') {
        handle = window.location.href;
      }

      getTally()?.openPopup(formId, {
        onClose,
        onSubmit,
        showOnce: true,
        layout: 'modal',
        autoClose: 3000,
        key: user?.email,
        hiddenFields: {
          handle,
          email: user?.email,
          total_followers: mkit?.profile?.total_followers || 0,
        },
      });
    }
  }, [
    loaded,
    posthog,
    onboarding,
    user?.email,
    user?.handle,
    mkit?.profile?.total_followers,
  ]);

  return formId ? (
    <Script async onLoad={onLoad} src="https://tally.so/widgets/embed.js" />
  ) : null;
};
