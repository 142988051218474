import React from 'react';
import dynamic from 'next/dynamic';
import { Builder } from '@shared/types/builder';
import { DemoChartCard } from '@shared/ui/demo-card';
import { selectIsFreePlan, useSelector } from '@shared/redux';
import { ComponentNames } from '@shared/constants/componentNames';
import { getInstagramAccountDetails } from '@widgets/mediakit/helpers';
import {
  builderCardSizes,
  largeSize,
} from '@shared/constants/builderCardSizes';

const Area = dynamic(
  () => import('@ant-design/plots').then((mod) => mod.Area),
  {
    ssr: false,
  },
);

type NameType =
  | ComponentNames.instagram_likes_growth
  | ComponentNames.instagram_followers_growth;

const dummyData: Record<
  NameType,
  {
    min: number;
    max: number;
    data: {
      month: string;
      value: number;
    }[];
  }
> = {
  [ComponentNames.instagram_likes_growth]: {
    min: 6177,
    max: 72043,
    data: [
      {
        month: '2023-09',
        value: 21682,
      },
      {
        month: '2023-10',
        value: 38469,
      },
      {
        month: '2023-11',
        value: 55256,
      },
      {
        month: '2023-12',
        value: 72043,
      },
      {
        month: '2024-01',
        value: 50088,
      },
      {
        month: '2024-02',
        value: 28132,
      },
      {
        month: '2024-03',
        value: 6177,
      },
    ],
  },
  [ComponentNames.instagram_followers_growth]: {
    min: 70894,
    max: 106874,
    data: [
      {
        month: '2023-09',
        value: 70894,
      },
      {
        month: '2023-10',
        value: 79286,
      },
      {
        month: '2023-11',
        value: 87679,
      },
      {
        month: '2023-12',
        value: 96072,
      },
      {
        month: '2024-01',
        value: 99673,
      },
      {
        month: '2024-02',
        value: 103273,
      },
      {
        month: '2024-03',
        value: 106874,
      },
    ],
  },
};

export function InstagramLikesFollowersGrowthDemo({
  data,
  size,
  onAddItem,
}: Builder.DemoComponentProps) {
  const isFree = useSelector(selectIsFreePlan);
  const detailsFunc = getInstagramAccountDetails()[data.name];

  const { title } = detailsFunc({
    size: builderCardSizes.s12,
  });

  const tt = (data?.data || []).map((item: any) => {
    return item.value;
  });

  let value = data?.data;

  let max = Math.max(...tt);
  let min = Math.min(...tt);

  if (isFree) {
    const dd = dummyData[data.name as NameType];

    max = dd.max;
    min = dd.min;

    value = dd.data;
  }

  return (
    <DemoChartCard
      size={size}
      title={title}
      w={largeSize.w}
      h={largeSize.h}
      type={data.name}
      onClick={onAddItem}
    >
      <React.Suspense>
        <Area
          autoFit
          line={{}}
          axis={{
            x: false,
            y: {
              tick: false,
              grid: true,
              line: false,
              label: false,
              title: false,
              gridLineWidth: 0.5,
              gridStroke: 'black',
              gridLineDash: [0, 0],
              gridStrokeOpacity: 0.15,
            },
          }}
          scale={{
            y: {
              clamp: true,
              domain: [min, max],
            },
          }}
          xField="month"
          yField="value"
          tooltip={false}
          style={{ opacity: 0.25 }}
          data={{ type: 'inline', value: value || [] }}
        />
      </React.Suspense>
    </DemoChartCard>
  );
}
