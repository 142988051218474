import React from 'react';
import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { Text } from '@shared/ui/typography';
import { HandleIcon } from '@shared/ui/Icons';
import { hexToRgb } from '@shared/utils/hexToRgb';
import { defaultEndColor } from '@shared/constants';
import { fontVariants } from '@shared/constants/fontVariants';
import { textContrastChecker } from '@shared/utils/textContrastChecker';
import {
  useSelector,
  selectMediakitTheme,
  selectAuthUserHandle,
} from '@shared/redux';

const Container = styled('div')(() => ({
  gap: '.8rem',
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  textOverflow: 'ellipsis',
}));

const HandleSkeleton = styled(Skeleton)({
  [`&.${prefixCls}-skeleton`]: {
    width: 100,
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 18,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 18,
        marginBottom: 0,
      },
    },
  },
});

export const Handle: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const theme = useSelector(selectMediakitTheme);
  const handle = useSelector(selectAuthUserHandle);

  const { r, g, b } = hexToRgb(theme?.primary_color || defaultEndColor);
  const buttonTextColor = textContrastChecker(r, g, b);

  let fill: string;

  if (buttonTextColor === '#ffffff') {
    fill = theme?.primary_color || '';
  } else {
    fill = '#000000';
  }

  return (
    <Container>
      <HandleIcon fill={fill} />

      <HandleSkeleton
        paragraph={false}
        active={showSkeleton}
        loading={showSkeleton}
      >
        <Text className={fontVariants.opt3} ellipsis={{ tooltip: true }}>
          {handle}
        </Text>
      </HandleSkeleton>
    </Container>
  );
};
