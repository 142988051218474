import React from 'react';
import { Global } from '@emotion/react';
import { prefixCls } from '@shared/theme';
import { Builder } from '@shared/types/builder';
import { fontVariants } from '@shared/constants/fontVariants';
import { selectMediakitThemeFonts, useSelector } from '@shared/redux';

export const GlobalStyles = () => {
  const fonts = useSelector(selectMediakitThemeFonts);

  return !!fonts ? (
    <Global
      styles={[
        {
          '@font-face': {
            src: `url(${fonts.opt1.link})`,
            fontFamily: fonts.opt1.family_name,
          },
          [`.${fontVariants.opt1}`]: {
            fontStyle: fonts.opt1.font_style,
            fontWeight: fonts.opt1.font_weight,
            fontFamily: `${fonts.opt1.family_name}, sans-serif`,
          },
        },
        {
          '@font-face': {
            src: `url(${fonts.opt2.link})`,
            fontFamily: fonts.opt2.family_name,
          },
          [`.${fontVariants.opt2}, .${prefixCls}-typography.${fontVariants.opt2}`]:
            {
              fontStyle: fonts.opt2.font_style,
              fontWeight: fonts.opt2.font_weight,
              fontFamily: `${fonts.opt2.family_name}, sans-serif`,
            },
        },
        {
          '@font-face': {
            src: `url(${fonts.opt3.link})`,
            fontFamily: fonts.opt3.family_name,
          },
          [`.${fontVariants.opt3}`]: {
            fontStyle: fonts.opt3.font_style,
            fontWeight: fonts.opt3.font_weight,
            fontFamily: `${fonts.opt3.family_name}, sans-serif`,
          },
        },
      ]}
    />
  ) : null;
};

export const ServerGlobalStyles = ({
  fonts,
}: {
  fonts: Builder.Mediakit.Theme['fonts'];
}) => {
  return !!fonts ? (
    <Global
      styles={[
        {
          '@font-face': {
            src: `url(${fonts.opt1.link})`,
            fontFamily: fonts.opt1.family_name,
          },
          [`.${fontVariants.opt1}, .${prefixCls}-typography.${fontVariants.opt1}`]:
            {
              fontStyle: fonts.opt1.font_style,
              fontWeight: fonts.opt1.font_weight,
              fontFamily: `${fonts.opt1.family_name}, sans-serif`,
            },
        },
        {
          '@font-face': {
            src: `url(${fonts.opt2.link})`,
            fontFamily: fonts.opt2.family_name,
          },
          [`.${fontVariants.opt2}, .${prefixCls}-typography.${fontVariants.opt2}`]:
            {
              fontStyle: fonts.opt2.font_style,
              fontWeight: fonts.opt2.font_weight,
              fontFamily: `${fonts.opt2.family_name}, sans-serif`,
            },
        },
        {
          '@font-face': {
            src: `url(${fonts.opt3.link})`,
            fontFamily: fonts.opt3.family_name,
          },
          [`.${fontVariants.opt3}`]: {
            fontStyle: fonts.opt3.font_style,
            fontWeight: fonts.opt3.font_weight,
            fontFamily: `${fonts.opt3.family_name}, sans-serif`,
          },
        },
      ]}
    />
  ) : null;
};
