import React from 'react';
import Image from 'next/image';
import { Builder } from '@shared/types/builder';
import { Root } from '@shared/types/builder/fontGroups';
import { FontComponentsContainer } from '@shared/ui/demo-components-container';
import {
  useDispatch,
  useSelector,
  selectThemeColors,
  selectDrawerTheme,
  selectThemeFilter,
  onUpdateThemeFonts,
  selectMediakitTheme,
  selectFontGroupArray,
} from '@shared/redux';
import {
  FontCard,
  imageStyles,
  ImageWrapper,
} from '@widgets/mediakit/theme-drawer/ui/utils';

export const Fonts: React.FC<{
  slug: string;
  username: string;
}> = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectMediakitTheme);
  const themeFilter = useSelector(selectThemeFilter);
  const drawerTheme = useSelector(selectDrawerTheme);
  const colorsObject = useSelector(selectThemeColors);
  const fontGroupArray = useSelector(selectFontGroupArray);
  const [localFonts, setLocalFonts] = React.useState<Root[]>([]);
  const [selectedGroupId, setSelectedGroupId] = React.useState<string>('');

  React.useEffect(() => {
    if (theme?.fonts?.group_id) {
      setSelectedGroupId(`${theme?.fonts?.group_id}`);
    }

    return function () {
      setSelectedGroupId('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeFilter]);

  React.useEffect(() => {
    if (fontGroupArray?.length) {
      setLocalFonts(fontGroupArray);
    }
  }, [setLocalFonts, fontGroupArray]);

  const compareFn = (a: Root) => {
    const selected = selectedGroupId === `${a.id}`;
    if (selected) {
      return -1;
    }

    return 0;
  };

  const onSelect = (font: Root) => () => {
    dispatch(
      onUpdateThemeFonts({
        group_id: `${font.id}`,
        opt1: {
          link: font['1'].link,
          variant: font['1'].variant,
          font_style: font['1'].font_style,
          font_weight: font['1'].font_weight,
          family_name: font['1'].family_name,
        },
        opt2: {
          link: font['2'].link,
          variant: font['2'].variant,
          font_style: font['2'].font_style,
          font_weight: font['2'].font_weight,
          family_name: font['2'].family_name,
        },
        opt3: {
          link: font['3'].link,
          variant: font['3'].variant,
          font_style: font['3'].font_style,
          font_weight: font['3'].font_weight,
          family_name: font['3'].family_name,
        },
      }),
    );
  };

  let currentColor: Builder.Theme.Color;

  if (theme?.color_id) {
    currentColor = colorsObject[theme.color_id];
  }

  return (
    <FontComponentsContainer>
      {localFonts.sort(compareFn).map((font) => {
        const selected = `${theme?.fonts?.group_id}` === `${font.id}`;

        let className = '';
        let border = '3px solid rgba(255, 255, 255, 0.15)';

        if (selected) {
          className = 'selected-palette';
          border = `3px solid ${currentColor?.code}`;
        }

        return (
          <FontCard
            key={font.id}
            border={border}
            onClick={onSelect(font)}
            drawerTheme={drawerTheme}
            className={`font-card ${className}`}
          >
            <ImageWrapper>
              <Image
                fill
                sizes="100%"
                quality={100}
                src={font.image}
                style={imageStyles}
                alt="Background Image"
              />
            </ImageWrapper>
          </FontCard>
        );
      })}
    </FontComponentsContainer>
  );
};
