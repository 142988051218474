import React from 'react';
import { Button } from 'antd';
import { Box } from '@shared/ui/box';
import { Builder } from '@shared/types/builder';
import { Carousel } from '@shared/ui/Carousel';
import { ThemeFilter } from '@shared/constants/themeFilter';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  ColorCard,
  wrapperStyles,
} from '@widgets/mediakit/theme-drawer/ui/utils';
import { SeeAll } from '@widgets/mediakit/drawer-components/see-all';
import { PaletteTags } from '@widgets/mediakit/theme-drawer/ui/palette-tags';
import { ShuffleButton } from '@widgets/mediakit/theme-drawer/ui/shuffle-button';
import {
  useSelector,
  useDispatch,
  onUpdateTheme,
  selectDrawerTheme,
  selectThemeColors,
  onChangeThemeFilter,
  selectMediakitTheme,
} from '@shared/redux';
import {
  HeadTitle,
  ButtonIcon,
  DemoComponentsHead,
  DemoComponentsContainer,
} from '@shared/ui/demo-components-container';
import useBreakpoint from '@shared/hooks/useBreakpoint';

export const ColorPalette: React.FC<{ seeAll?: boolean }> = ({ seeAll }) => {
  return (
    <DemoComponentsContainer overflow={!seeAll ? 'initial' : 'hidden'}>
      <DemoComponentsHead>
        <Head seeAll={seeAll} />
      </DemoComponentsHead>

      <Body seeAll={seeAll} />
    </DemoComponentsContainer>
  );
};

const Head: React.FC<{ seeAll?: boolean }> = ({ seeAll }) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const theme = useSelector(selectMediakitTheme);
  const drawerTheme = useSelector(selectDrawerTheme);
  const colorsObject = useSelector(selectThemeColors);

  let currentColor: Builder.Theme.Color = {} as Builder.Theme.Color;

  if (theme?.color_id) {
    currentColor = colorsObject[theme.color_id];
  }

  const handleChangeFilter = (filter?: ThemeFilter | number) => () => {
    dispatch(onChangeThemeFilter(filter));

    if (filter) {
      try {
        posthog?.capture(PosthogEvents.SeeAllClicked, {
          section: 'Color Palette',
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  return seeAll ? (
    <HeadTitle
      strong
      type="secondary"
      cursor="pointer"
      drawerTheme={drawerTheme}
      onClick={handleChangeFilter(ThemeFilter.colors)}
    >
      <ButtonIcon />
      Color Palette
    </HeadTitle>
  ) : (
    <>
      <HeadTitle drawerTheme={drawerTheme} strong type="secondary">
        Color Palette
      </HeadTitle>
      <SeeAll count={100} onClick={handleChangeFilter(currentColor.id)} />
    </>
  );
};

const Body: React.FC<{ seeAll?: boolean }> = ({ seeAll }) => {
  const dispatch = useDispatch();
  const is768 = useBreakpoint(768);
  const theme = useSelector(selectMediakitTheme);
  const colorsObject = useSelector(selectThemeColors);

  const [selectedPalette, setSelectedPalette] = React.useState(0);

  React.useEffect(() => {
    setSelectedPalette(theme?.palette_id || 0);

    return function () {
      setSelectedPalette(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let currentColor: Builder.Theme.Color = {} as Builder.Theme.Color;

  if (theme?.color_id) {
    currentColor = colorsObject[theme.color_id];
  }

  const palette = currentColor?.palettes?.find(
    (p) => p.palette_id === theme?.palette_id,
  );

  const [currentPalette, setCurrentPalette] = React.useState<
    Builder.Theme.Palette[]
  >(currentColor?.palettes || []);

  React.useEffect(() => {
    if (!palette?.palette_id) {
      setCurrentPalette((prev) => {
        const f = prev.find((p) => p.palette_id === theme?.palette_id);

        if (f) {
          return prev;
        }

        return [
          {
            default: 0,
            codes: theme?.palette ?? '',
            color_id: theme?.color_id ?? 0,
            palette_id: theme?.palette_id ?? 0,
          },
          ...prev,
        ];
      });
    }
  }, [
    theme?.palette,
    theme?.color_id,
    currentColor.id,
    theme?.palette_id,
    palette?.palette_id,
  ]);

  React.useEffect(() => {
    setCurrentPalette(currentColor.palettes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentColor.id]);

  const compareFn = (a: Builder.Theme.Palette) => {
    const selected = a.palette_id === selectedPalette;

    if (selected) {
      return -1;
    }

    return 0;
  };

  return seeAll ? (
    <PaletteTags currentColor={currentColor} currentColorId={currentColor.id} />
  ) : !is768 ? (
    <Carousel size="small">
      {[...currentPalette].sort(compareFn).map((color, index) => {
        let className = '';
        let style: React.CSSProperties = {
          marginTop: 0,
          marginLeft: 4,
          marginRight: 4,
          marginBottom: 0,
        };

        if (index === 0) {
          style = {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 4,
            marginBottom: 0,
          };
        } else if (index === currentPalette.length - 1) {
          style = {
            marginTop: 0,
            marginLeft: 4,
            marginRight: 4,
            marginBottom: 0,
          };
        }

        const colors = color.codes.split(',');

        let border = '3px solid rgba(0, 0, 0, 0.15)';

        if (color.palette_id === theme?.palette_id) {
          className = 'selected-palette';
          border = `3px solid ${currentColor?.code}`;
        }

        const onClick = () => {
          if (!className) {
            dispatch(
              onUpdateTheme({
                palette: color.codes,
                palette_id: color.palette_id,
                shuffled_palette: color.codes,
                primary_color: currentColor.code,
              }),
            );
          }
        };

        return (
          <div key={color.codes}>
            <div style={style}>
              <ColorCard
                border={border}
                key={color.codes}
                onClick={onClick}
                className={className}
              >
                {!!className && <ShuffleButton />}
                {colors.map((color) => {
                  return (
                    <Box
                      key={color}
                      boxStyles={{ backgroundColor: color, width: '25%' }}
                    />
                  );
                })}
              </ColorCard>
            </div>
          </div>
        );
      })}
    </Carousel>
  ) : (
    <Box
      boxStyles={{
        width: '100%',
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar:horizontal': {
          display: 'none',
        },
      }}
    >
      {[...currentPalette].sort(compareFn).map((color, index) => {
        let className = '';
        let style: React.CSSProperties = {
          marginTop: 0,
          marginLeft: 4,
          marginRight: 4,
          marginBottom: 0,
        };

        if (index === 0) {
          style = {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 4,
            marginBottom: 0,
          };
        } else if (index === currentPalette.length - 1) {
          style = {
            marginTop: 0,
            marginLeft: 4,
            marginRight: 4,
            marginBottom: 0,
          };
        }

        const colors = color.codes.split(',');

        let border = '3px solid rgba(0, 0, 0, 0.15)';

        if (color.palette_id === theme?.palette_id) {
          className = 'selected-palette';
          border = `3px solid ${currentColor?.code}`;
        }

        const onClick = () => {
          if (!className) {
            dispatch(
              onUpdateTheme({
                palette: color.codes,
                palette_id: color.palette_id,
                shuffled_palette: color.codes,
                primary_color: currentColor.code,
              }),
            );
          }
        };

        return (
          <div key={color.codes}>
            <div style={style}>
              <ColorCard
                border={border}
                key={color.codes}
                onClick={onClick}
                className={className}
              >
                {!!className && <ShuffleButton />}
                {colors.map((color) => {
                  return (
                    <Box
                      key={color}
                      boxStyles={{ backgroundColor: color, width: '25%' }}
                    />
                  );
                })}
              </ColorCard>
            </div>
          </div>
        );
      })}
    </Box>
  );
};
