import React from 'react';
import Image from 'next/image';
import { Builder } from '@shared/types/builder';
import { Card, imageStyles, ImageWrapper } from './utils';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { BackgroundTypes } from '@shared/constants/background-types';
import { ThemeComponentsContainer } from '@shared/ui/demo-components-container';
import {
  useDispatch,
  useSelector,
  onUpdateTheme,
  selectBgImages,
  selectThemeColors,
  selectThemeFilter,
  selectDrawerTheme,
  selectMediakitTheme,
} from '@shared/redux';

export const ViralMangoImages = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const bgImages = useSelector(selectBgImages);
  const theme = useSelector(selectMediakitTheme);
  const drawerTheme = useSelector(selectDrawerTheme);
  const themeFilter = useSelector(selectThemeFilter);
  const colorsObject = useSelector(selectThemeColors);

  const [selectedBgImageId, setSelectedBgImageId] = React.useState<number>(0);

  React.useEffect(() => {
    if (theme?.bg_image_id) {
      setSelectedBgImageId(theme?.bg_image_id);
    }

    return function () {
      setSelectedBgImageId(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeFilter]);

  if (!bgImages) {
    return null;
  }

  let currentColor: Builder.Theme.Color;

  if (theme?.color_id) {
    currentColor = colorsObject[theme.color_id];
  }

  const compareFn = (a: Builder.BgImage) => {
    if (selectedBgImageId === a.id) {
      return -1;
    }

    return 0;
  };

  const onSelect = (image: Builder.BgImage) => () => {
    if (theme?.bg_image_id === image.id) {
      return;
    }

    dispatch(
      onUpdateTheme({
        bg_image: image.link,
        bg_image_id: image.id,
        bg_image_type: BackgroundTypes.ViralMango,
      }),
    );

    try {
      posthog?.capture(PosthogEvents.BackgroundType, {
        type: 'vm',
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <ThemeComponentsContainer>
      {[...bgImages].sort(compareFn).map((image, index) => {
        const selected = theme?.bg_image_id === image.id;

        let className = '';
        let border = '3px solid rgba(0, 0, 0, 0.15)';

        if (drawerTheme === 'dark') {
          border = '3px solid rgba(255, 255, 255, 0.20)';
        }

        let backgroundColor: string | undefined = '#001529';

        if (selected) {
          className = 'selected-palette';
          border = `3px solid ${currentColor?.code}`;

          if (drawerTheme === 'dark') {
            backgroundColor = '#fff';
          }
        }

        return (
          <Card
            key={index}
            border={border}
            className={className}
            onClick={onSelect(image)}
            backgroundColor={backgroundColor}
          >
            <ImageWrapper>
              <Image
                fill
                quality={50}
                sizes="100%"
                src={image.link}
                style={imageStyles}
                alt="Background Image"
              />
            </ImageWrapper>
          </Card>
        );
      })}
    </ThemeComponentsContainer>
  );
};
