import React from 'react';
import Image from 'next/image';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { Box } from '@shared/ui/box';
import { DemoCardSize } from '@shared/constants';
import theme, { lightBoxShadow } from '@shared/theme';
import tiktokLight from '@shared/icons/tiktok-light.svg';
import youtubeLight from '@shared/icons/youtube-light.svg';
import instagramLight from '@shared/icons/instagram-light.svg';
import { CardHelper } from '@widgets/mediakit/components-drawer/components/CardHelper';

const { token } = theme;

interface CardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  w: number;
  h: number;
  type: string;
  title: string;
  value?: string;
  size?: DemoCardSize;
  description?: React.ReactNode;
}

const CardHeader = styled('div')({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
});

const CardContent = styled('div')({
  gap: '.8rem',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'column',
});

const CardBody = styled('div', {
  shouldForwardProp: (propName) => propName !== 'size',
})(({ size }: { size?: DemoCardSize }) => ({
  display: 'flex',
  cursor: 'pointer',
  padding: '1.2rem',
  borderRadius: '.8rem',
  backgroundColor: '#fff',
  flexDirection: 'column',
  boxShadow: lightBoxShadow,
  width: size === DemoCardSize.large ? 170 : 132,
  height: size === DemoCardSize.large ? 170 : 132,
  border: `1px solid ${token!.colorBorderSecondary}`,
  gap: size === DemoCardSize.large ? '3.2rem' : '2.4rem',
}));

const CardChartBody = styled('div', {
  shouldForwardProp: (propName) => propName !== 'size',
})(({ size }: { size?: DemoCardSize }) => ({
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '.8rem',
  backgroundColor: '#fff',
  flexDirection: 'column',
  boxShadow: lightBoxShadow,
  width: size === DemoCardSize.large ? 170 : 132,
  height: size === DemoCardSize.large ? 170 : 132,
  border: `1px solid ${token!.colorBorderSecondary}`,
}));

const CardTitle = styled(Typography.Text, {
  shouldForwardProp: (propName) => propName !== 'size',
})(({ size }: { size?: DemoCardSize }) => ({
  fontSize: size === DemoCardSize.large ? '1.6rem' : '1rem',
  lineHeight: size === DemoCardSize.large ? '2.4rem' : '2rem',
}));

const CardDescription = styled(Typography.Text, {
  shouldForwardProp: (propName) => propName !== 'size',
})(({ size }: { size?: DemoCardSize }) => ({
  fontWeight: 600,
  fontSize: size === DemoCardSize.large ? '3rem' : '2.4rem',
  lineHeight: size === DemoCardSize.large ? '3.8rem' : '3.2rem',
}));

const guessIcon = (type: string) => {
  const t = type.substring(0, 3);

  switch (t) {
    case 'tk_':
      return tiktokLight;
    case 'yt_':
      return youtubeLight;
    case 'ig_':
      return instagramLight;
    default:
      return instagramLight;
  }
};

export const DemoCard: React.FC<CardProps> = ({
  w,
  h,
  size,
  type,
  value,
  title,
  onClick,
  description,
}) => {
  const icon = guessIcon(type);

  return (
    <CardContent data-w={w} data-h={h} data-type={type} onClick={onClick}>
      <CardBody size={size}>
        <CardHeader>
          <Image width={14} height={14} src={icon} alt={type} />
          <CardTitle ellipsis size={size}>
            {title}
          </CardTitle>
        </CardHeader>
        {!!description ? (
          description
        ) : (
          <CardDescription size={size}>{value}</CardDescription>
        )}
      </CardBody>
      <CardHelper size={size}>{title}</CardHelper>
    </CardContent>
  );
};

export const DemoChartCard: React.FC<React.PropsWithChildren<CardProps>> = ({
  w,
  h,
  size,
  type,
  title,
  onClick,
  children,
}) => {
  return (
    <CardContent data-w={w} data-h={h} data-type={type} onClick={onClick}>
      <CardChartBody size={size}>{children}</CardChartBody>
      <CardHelper size={size}>{title}</CardHelper>
      <Box
        boxStyles={{
          top: 0,
          left: 0,
          zIndex: 100,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          position: 'absolute',
        }}
      />
    </CardContent>
  );
};
