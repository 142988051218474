import React from 'react';
import { Builder } from '@shared/types/builder';
import * as Demo from '@widgets/mediakit/components-drawer/components/DemoComponents/Instagram';

export const componentsMap: Record<
  string,
  (props: Builder.DemoComponentProps) => React.JSX.Element
> = {
  ig_profile: Demo.InstagramProfileDemo,
  ig_avg_likes: Demo.InstagramAccountDemo,
  ig_followers: Demo.InstagramAccountDemo,
  ig_top_cities: Demo.InstagramTopCitiesDemo,
  ig_avg_comments: Demo.InstagramAccountDemo,
  ig_avg_reels_plays: Demo.InstagramAccountDemo,
  ig_engagement_rate: Demo.InstagramAccountDemo,
  ig_audience_genders: Demo.InstagramSexRatioDemo,
  ig_top_countries: Demo.InstagramTopCountriesDemo,
  ig_audience_genders_per_age: Demo.InstagramSexAgeRatioDemo,
  ig_followers_growth: Demo.InstagramLikesFollowersGrowthDemo,
  ig_total_likes_growth: Demo.InstagramLikesFollowersGrowthDemo,
};
