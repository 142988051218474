import React from 'react';
import { Container } from './container';
import { avatarSizeMD } from './constants';
import { DropAvatar } from './drop-avatar';
import { useRouter } from 'next/navigation';
import { AvatarLabel } from './avatar-label';
import { MenuInfo } from 'rc-menu/lib/interface';
import { SkeletonAvatar } from './skeleton-avatar';
import { CharsContainer } from './chars-container';
import { LogoutOutlined } from '@ant-design/icons';
import { AppRoutes } from '@shared/constants/appRoutes';
import { useLogoutMutation } from '@shared/services/auth';
import { selectAuthUser, useSelector } from '@shared/redux';
import { Badge, Dropdown, MenuProps, Typography } from 'antd';

const { Text } = Typography;

const items: MenuProps['items'] = [
  {
    key: 'myAvatar',
    label: <AvatarLabel />,
  },
  {
    key: 'divider_1',
    type: 'divider',
  },
  {
    key: 'sign_out',
    label: 'Sign Out',
    icon: <LogoutOutlined />,
  },
];

export const ProfileMenu: React.FC<{
  bordered?: boolean;
  showSkeleton?: boolean;
}> = ({ bordered, showSkeleton }) => {
  const { replace } = useRouter();
  const [logout, res] = useLogoutMutation();
  const authUser = useSelector(selectAuthUser);

  const chars = authUser?.email?.slice(0, 2)?.toUpperCase();

  React.useEffect(() => {
    if (res.isSuccess) {
      replace(AppRoutes.login);
    }
  }, [replace, res.isSuccess]);

  const onClick = async (info: MenuInfo) => {
    switch (info.key) {
      case 'sign_out': {
        await logout();

        if (typeof window === 'object') {
          window.location.replace(AppRoutes.login);
        }
        break;
      }
    }
  };

  return (
    <Container>
      {!authUser || showSkeleton ? (
        <SkeletonAvatar active shape="circle" size={40} />
      ) : (
        <Dropdown trigger={['click']} menu={{ items, onClick }}>
          <Badge>
            <DropAvatar
              shape="circle"
              size={avatarSizeMD}
              bordered={bordered}
              src={authUser?.profile_photo_path}
            >
              <CharsContainer>
                <Text color="primary">{chars}</Text>
              </CharsContainer>
            </DropAvatar>
          </Badge>
        </Dropdown>
      )}
    </Container>
  );
};
