import React from 'react';
import Image from 'next/image';
import { Box } from '@shared/ui/box';
import { Builder } from '@shared/types/builder';
import { fastAnimation } from '@shared/theme';
import shuffleIcon from '@shared/icons/shuffle.svg';
import {
  onUpdateTheme,
  selectColors,
  selectMediakitTheme,
  useDispatch,
  useSelector,
} from '@shared/redux';

const randomIndexes: number[][] = [
  [1, 2, 3, 4, 5],

  [2, 1, 3, 4, 5],
  [5, 1, 3, 4, 2],
  [3, 2, 1, 4, 5],
  [5, 2, 1, 4, 3],
  [5, 4, 1, 2, 3],
  [4, 2, 3, 1, 5],
  [5, 2, 3, 1, 4],
  [5, 1, 3, 2, 4],
  [5, 2, 3, 4, 1],
  [5, 4, 3, 2, 1],
];

export const ShuffleButton = () => {
  const dispatch = useDispatch();
  const colors = useSelector(selectColors) as Builder.Theme.ItemsObject;
  const theme = useSelector(selectMediakitTheme) as Builder.Mediakit.Theme;

  const onShuffle = () => {
    if (theme) {
      const originalPalette = theme.palette?.split(',') || [];
      const primaryColor = colors?.[theme.color_id].code;

      originalPalette.unshift(primaryColor);

      const originalObject = originalPalette.reduce<Record<string, string>>(
        (acc, curr, index) => {
          acc[curr] = `${index + 1}`;

          return acc;
        },
        {},
      );

      const original = originalPalette.reduce<Record<string, string>>(
        (acc, curr, index) => {
          acc[`${index + 1}`] = curr;

          return acc;
        },
        {},
      );

      const shuffledPalette = theme.shuffled_palette?.split(',') || [];
      shuffledPalette.unshift(theme.primary_color);
      const shuffledValues: any = [];

      for (let i = 0; i < shuffledPalette.length; i++) {
        const currentColor = shuffledPalette[i];
        const currentValue = originalObject[currentColor];

        shuffledValues.push(currentValue);
      }

      const currentIndex = randomIndexes.findIndex(
        (i) => i.join(',') === shuffledValues.join(','),
      );

      const getNext = (
        index: number,
      ): {
        nextPrimaryColor: string;
        nextShuffledPalette: string;
      } => {
        let nextPaletteValues = randomIndexes[index + 1];

        if (!nextPaletteValues) {
          nextPaletteValues = randomIndexes[0];
        }

        const nextShuffledPalette = nextPaletteValues.map((value) => {
          return original[value];
        });

        const nextPrimaryColor = nextShuffledPalette.shift() as string;

        const prev = `${theme.primary_color}${theme.palette}`;
        const next = `${nextPrimaryColor}${nextShuffledPalette.join(',')}`;

        if (next === prev) {
          return getNext(index + 1);
        }

        return {
          nextPrimaryColor,
          nextShuffledPalette: nextShuffledPalette.join(','),
        };
      };

      const { nextPrimaryColor, nextShuffledPalette } = getNext(currentIndex);

      dispatch(
        onUpdateTheme({
          primary_color: nextPrimaryColor,
          shuffled_palette: nextShuffledPalette,
        }),
      );
    }
  };

  return (
    <Box
      onClick={onShuffle}
      boxStyles={{
        top: 0,
        left: 0,
        right: 0,
        width: 33,
        bottom: 0,
        height: 32,
        margin: 'auto',
        cursor: 'pointer',
        borderRadius: '50%',
        position: 'absolute',
        willChange: 'opacity, background-color',
        transition: `opacity ${fastAnimation} ease, background-color ${fastAnimation} ease`,
        '&:hover': {
          backgroundColor: '#00000080',
        },
      }}
      className="selected-palette-shuffle-icon"
    >
      <Image src={shuffleIcon} alt="Shuffle Icon" />
    </Box>
  );
};
