import React from 'react';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { LineChartOutlined } from '@ant-design/icons';
import { useSelector, selectVisits } from '@shared/redux';
import { abbreviateNumber } from '@shared/utils/abbreviateNumber';
import { Button, Flex, Popover, Skeleton, Typography } from 'antd';

const ButtonSkeleton = () => {
  const is768 = useBreakpoint(768);
  const style = {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: is768 ? 40 : 108,
    minWidth: is768 ? 'unset' : void 0,
  };

  return <Skeleton.Button active size="large" style={style} />;
};

export const VisitorsButton: React.FC<{
  scrolled?: boolean;
  showSkeleton?: boolean;
}> = ({ scrolled, showSkeleton }) => {
  const matches = useBreakpoint(768);
  const visits = useSelector(selectVisits);

  const content = (
    <Flex vertical>
      <Typography.Text style={{ fontSize: 14, fontWeight: 600 }}>
        Media Kit Visitors
      </Typography.Text>
      <Typography.Text style={{ fontSize: 32, fontWeight: 700 }}>
        {abbreviateNumber(visits?.total || 0)}
      </Typography.Text>
    </Flex>
  );

  return (visits?.total || 0) >= 50 ? (
    showSkeleton ? (
      <ButtonSkeleton />
    ) : (
      <Popover content={content} trigger={['click']}>
        <Button
          size="large"
          type={scrolled ? 'text' : void 0}
          icon={<LineChartOutlined style={{ verticalAlign: 'middle' }} />}
        >
          {!matches && 'Visitors'}
        </Button>
      </Popover>
    )
  ) : null;
};
