import React from 'react';
import Image from 'next/image';
import { v4 as uuidV4 } from 'uuid';
import { Builder } from '@shared/types/builder';
import { Title } from '@shared/ui/typography';
import ytIcon from '@shared/icons/youtube.svg';
import { blurDataURL } from '@shared/constants';
import fallback from '@shared/icons/fallback.png';
import { onAddItem, useDispatch } from '@shared/redux';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { largeSize } from '@shared/constants/builderCardSizes';
import { abbreviateNumber } from '@shared/utils/abbreviateNumber';
import { ComponentNames } from '@shared/constants/componentNames';
import { SocialProfileTarget } from '@widgets/mkit-tour/ui/social-profile-target';
import { CardHelper } from '@widgets/mediakit/components-drawer/components/CardHelper';
import {
  imageStyles,
  ProfileCard,
  PostsContainer,
  ImageContainer,
  ProfileCardBody,
  HandleContainer,
  ProfileCardHead,
  ProfileCardHeadExtra,
} from './styles';

const keys = [
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
];

export function YouTubeProfileDemo({ size, data }: Builder.DemoComponentProps) {
  const posts = data?.posts;
  const dispatch = useDispatch();
  const hasPosts = !!posts?.length;

  const addSocial = () => {
    dispatch(
      onAddItem({
        w: largeSize.w,
        h: largeSize.h,
        i: ComponentNames.youtube_profile,
      }),
    );
  };

  return (
    <ProfileCard>
      <SocialProfileTarget />
      <ProfileCardBody
        size={size}
        onClick={addSocial}
        data-w={largeSize.w}
        data-h={largeSize.h}
        data-type={data.name}
      >
        <ProfileCardHead>
          <ProfileCardHeadExtra>
            <Image width={17} height={17} src={ytIcon} alt="YouTube" />
            <HandleContainer>
              <Title ellipsis level={5}>
                @{data.handle}
              </Title>
            </HandleContainer>
          </ProfileCardHeadExtra>
          <Title ellipsis level={5}>
            {abbreviateNumber(data.followers)}
          </Title>
        </ProfileCardHead>
        {hasPosts ? (
          <PostsContainer>
            {posts.map(({ image }, index) => {
              return (
                <ImageContainer key={keys[index]}>
                  <ImageWithFallback
                    fill
                    sizes="100% 100%"
                    alt="YouTube post"
                    placeholder="blur"
                    style={imageStyles}
                    fallbackSrc={fallback}
                    blurDataURL={blurDataURL}
                    src={image?.trim() || fallback}
                  />
                </ImageContainer>
              );
            })}
          </PostsContainer>
        ) : null}
      </ProfileCardBody>
      <CardHelper size={size}>Profile</CardHelper>
    </ProfileCard>
  );
}
