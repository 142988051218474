import React from 'react';
import Image from 'next/image';
import { Typography } from 'antd';
import { Box } from '@shared/ui/box';
import { Builder } from '@shared/types/builder';
import { DemoCardSize } from '@shared/constants';
import { DemoChartCard } from '@shared/ui/demo-card';
import { selectIsFreePlan, useSelector } from '@shared/redux';
import { getInstagramAccountDetails } from '@widgets/mediakit/helpers';
import {
  builderCardSizes,
  largeSize,
} from '@shared/constants/builderCardSizes';

const dummyData = [
  {
    name: 'New York City',
    weight: 0.014778,
    country: {
      id: 148838,
      code: 'US',
      name: 'United States',
    },
    followers: 1579,
    code: 'US',
  },
  {
    name: 'São Paulo',
    weight: 0.013136,
    country: {
      id: 59470,
      code: 'BR',
      name: 'Brazil',
    },
    followers: 1404,
    code: 'BR',
  },
  {
    name: 'Moscow',
    weight: 0.011084,
    country: {
      id: 60189,
      code: 'RU',
      name: 'Russia',
    },
    followers: 1185,
    code: 'RU',
  },
  {
    name: 'Los Angeles',
    weight: 0.010263,
    country: {
      id: 148838,
      code: 'US',
      name: 'United States',
    },
    followers: 1097,
    code: 'US',
  },
  {
    name: 'Istanbul',
    weight: 0.0078,
    country: {
      id: 174737,
      code: 'TR',
      name: 'Turkey',
    },
    followers: 834,
    code: 'TR',
  },
];

export function InstagramTopCitiesDemo({
  data,
  size,
  onAddItem,
}: Builder.DemoComponentProps) {
  const isFree = useSelector(selectIsFreePlan);
  const detailsFunc = getInstagramAccountDetails()[data.name];

  const { title } = detailsFunc({
    size: builderCardSizes.s12,
  });

  let localData = ([...(data?.data || [])] || [])
    .sort((a: any, b: any) => {
      return b.weight - a.weight;
    })
    .map((item: any) => {
      return {
        ...item,
        code: item.country.code,
      };
    })
    .slice(0, 5);

  if (isFree) {
    localData = [...dummyData];
  }

  if (size !== DemoCardSize.large) {
    localData = localData.slice(0, 4);
  }

  return (
    <DemoChartCard
      size={size}
      title={title}
      w={largeSize.w}
      h={largeSize.h}
      type={data.name}
      onClick={onAddItem}
    >
      <Box
        boxStyles={{
          gap: '1.6rem',
          padding: '22px 16px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {localData.map((item: any, index: number) => {
          const percent = Number((item.weight * 100).toFixed(2));

          return (
            <Box
              key={index}
              boxStyles={{
                width: '100%',
                maxWidth: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                boxStyles={{
                  gap: '0.8rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Image
                  alt={''}
                  width={16}
                  height={12}
                  src={`/countries/country-${item.code?.toLowerCase()}.svg`}
                />
                <Typography.Text
                  style={{
                    fontSize: 6,
                    lineHeight: '10px',
                  }}
                >
                  {item.name}
                </Typography.Text>
              </Box>

              <Typography.Text
                style={{
                  fontSize: 6,
                  lineHeight: '8px',
                  color: '#00000073',
                  whiteSpace: 'nowrap',
                }}
              >
                {percent}%
              </Typography.Text>
            </Box>
          );
        })}
      </Box>
    </DemoChartCard>
  );
}
