import React from 'react';
import { useSelector, selectMediakitTheme } from '@shared/redux';
import { BgTypes } from '@widgets/mediakit/theme-drawer/ui/bg-types';
import { BackgroundTypes } from '@shared/constants/background-types';
import { StaticImage } from '@widgets/mediakit/theme-drawer/ui/static-image';
import { UnsplashImages } from '@widgets/mediakit/theme-drawer/ui/unsplash-images';
import { ViralMangoImages } from '@widgets/mediakit/theme-drawer/ui/viral-mango-images';

export const Background: React.FC<{
  slug: string;
  username: string;
  setIsUnsplash: (value: boolean) => void;
}> = (props) => {
  const theme = useSelector(selectMediakitTheme);
  const [bgImageType, setBgImageType] = React.useState<BackgroundTypes>(
    theme?.bg_image_type || BackgroundTypes.ViralMango,
  );

  const bgTypesOnChange = (value: BackgroundTypes) => {
    setBgImageType(value);

    props.setIsUnsplash(value === BackgroundTypes.Unsplash);
  };

  const filtersMapping = React.useMemo(() => {
    return {
      [BackgroundTypes.Unsplash]: <UnsplashImages />,
      [BackgroundTypes.ViralMango]: <ViralMangoImages />,
      [BackgroundTypes.Static]: (
        <StaticImage username={props.username} slug={props.slug} />
      ),
    };
  }, [props.slug, props.username]);

  const component = filtersMapping[bgImageType || BackgroundTypes.ViralMango];

  return (
    <>
      <BgTypes bgImageType={bgImageType} setBgImageType={bgTypesOnChange} />

      {component}
    </>
  );
};
