import React from 'react';
import { Builder } from '@shared/types/builder';
import { DemoCard } from '@shared/ui/demo-card';
import { getTikTokAccountDetails } from '@widgets/mediakit/helpers';
import {
  commonSizes,
  builderCardSizes,
} from '@shared/constants/builderCardSizes';

export function TikTokAccountDemo({
  data,
  size,
  onAddItem,
}: Builder.DemoComponentProps) {
  const detailsFunc = getTikTokAccountDetails()[data.name];

  const { title, value } = detailsFunc({
    value: data.value,
    size: builderCardSizes.s12,
  });

  return (
    <DemoCard
      size={size}
      title={title}
      value={value}
      type={data.name}
      h={commonSizes.h}
      w={commonSizes.w}
      onClick={onAddItem}
    />
  );
}
