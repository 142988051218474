import React from 'react';
import styled from '@emotion/styled';
import { Skeleton, Tag } from 'antd';
import { prefixCls } from '@shared/theme';
import { hexToRgb } from '@shared/utils/hexToRgb';
import { Categories } from '@shared/types/categories';
import { defaultEndColor } from '@shared/constants';
import { CloseCircleFilled } from '@ant-design/icons';
import { useOnboardingMutation } from '@shared/services/auth';
import { fontVariants } from '@shared/constants/fontVariants';
import { textContrastChecker } from '@shared/utils/textContrastChecker';
import {
  useDispatch,
  useSelector,
  selectCategories,
  onRemoveCategory,
  selectMediakitTheme,
  selectSelectedCategories,
  selectCategoriesHasSelected,
} from '@shared/redux';

let tagStyles: React.CSSProperties = {
  margin: 0,
  gap: '0.4rem',
  border: 'none',
  display: 'flex',
  height: '3.2rem',
  color: '#5956E9',
  fontSize: '1.4rem',
  alignItems: 'center',
  borderRadius: '0.8rem',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
};

const TagSkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    width: 44,
    height: 32,
    [`& > .${prefixCls}-skeleton-content`]: {
      width: 44,
      height: 32,
      [`& > .${prefixCls}-skeleton-title`]: {
        width: 44,
        height: 32,
        marginBottom: 0,
      },
    },
  },
}));

export const Tags: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const dispatch = useDispatch();
  const [onboarding] = useOnboardingMutation();
  const theme = useSelector(selectMediakitTheme);
  const allCategories = useSelector(selectCategories);
  const categories = useSelector(selectSelectedCategories);
  const categoriesHasSelected = useSelector(selectCategoriesHasSelected);

  const allCategoriesObject = allCategories.reduce<
    Record<string, Categories.Category>
  >((acc, curr) => {
    acc[`${curr.value}`] = curr;

    return acc;
  }, {});

  function remove(index: number) {
    dispatch(onRemoveCategory(index));

    if (!categoriesHasSelected) {
      onboarding({ step: 'general_info.categories' });
    }
  }

  const { r, g, b } = hexToRgb(theme?.primary_color || defaultEndColor);
  const buttonTextColor = textContrastChecker(r, g, b);

  if (buttonTextColor === '#ffffff') {
    tagStyles = {
      ...tagStyles,
      color: theme?.primary_color,
    };
  } else {
    tagStyles = {
      ...tagStyles,
      color: '#000000',
    };
  }

  return categories.map(({ value }, index) => {
    const onClose = () => remove(index);
    const tag = allCategoriesObject[value];

    return (
      <TagSkeleton
        paragraph={false}
        active={showSkeleton}
        loading={showSkeleton}
        key={tag?.value || value}
      >
        <Tag
          closable
          style={tagStyles}
          onClose={onClose}
          key={tag?.value || value}
          className={fontVariants.opt3}
          closeIcon={<CloseCircleFilled style={{ fontSize: 12 }} />}
        >
          {tag?.label || value}
        </Tag>
      </TagSkeleton>
    );
  });
};
