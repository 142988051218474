'use client';

import React from 'react';
import { Flex, Layout } from 'antd';
import styled from '@emotion/styled';
import * as Components from './components';
import { prefixCls, shadow } from '@shared/theme';
import { ProfileMenu } from '@shared/ui/profile-menu';
import useBreakpoint from '@shared/hooks/useBreakpoint';

const background =
  'linear-gradient(180deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%)';

const MkitHeader = styled(Layout.Header)(() => ({
  [`&.${prefixCls}-layout-header`]: {
    top: 0,
    background,
    zIndex: 150,
    display: 'flex',
    position: 'sticky',
    alignItems: 'center',
    padding: '1.2rem 2.4rem',
    justifyContent: 'space-between',
    transition: 'background 0.3s, box-shadow 0.3s',
    '@media (max-width: 768px)': {
      '&.scrolled': {
        boxShadow: shadow,
        background: '#fff',
      },
    },
  },
}));

export const Header: React.FC<{
  slug: string;
  username: string;
  showSkeleton?: boolean;
}> = ({ slug, username, showSkeleton }) => {
  const is768 = useBreakpoint(768);
  const [scrolled, setScrolled] = React.useState(false);

  React.useEffect(() => {
    if (!is768) {
      const header = document.querySelector('header');
      if (header) {
        header.classList.remove('scrolled');
      }
      return;
    }

    const handleScroll = () => {
      const header = document.querySelector('header');

      if (header) {
        const scrollPosition = window.scrollY;

        if (scrollPosition > 0) {
          header.classList.add('scrolled');

          setScrolled(true);
        } else {
          header.classList.remove('scrolled');

          setScrolled(false);
        }
      }
    };

    handleScroll();

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [is768, setScrolled]);

  return (
    <MkitHeader>
      <Flex gap={8} align="center">
        <Components.HomeButton showSkeleton={showSkeleton} />
        <Components.InfoButton
          showSkeleton={showSkeleton}
          scrolled={scrolled && is768}
        />
      </Flex>
      <Flex gap=".8rem">
        <Components.ViewToggle showSkeleton={showSkeleton} />
        <Components.TemplatesButton
          showSkeleton={showSkeleton}
          scrolled={scrolled && is768}
        />
        <Components.VisitorsButton
          showSkeleton={showSkeleton}
          scrolled={scrolled && is768}
        />
        <Components.ShareButton
          slug={slug}
          username={username}
          showSkeleton={showSkeleton}
          scrolled={scrolled && is768}
        />
        <ProfileMenu bordered showSkeleton={showSkeleton} />
      </Flex>
    </MkitHeader>
  );
};
