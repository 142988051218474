import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@shared/ui/box';
import { Text } from '@shared/ui/typography';
import { RightOutlined } from '@ant-design/icons';
import * as styles from '@shared/ui/Carousel/styles';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { Button, Carousel as AntdCarousel } from 'antd';
import { carouselStyles } from '@shared/ui/Carousel/styles';
import { selectDrawerTheme, useSelector } from '@shared/redux';

function SampleNextArrow(props: any) {
  const { onClick, className, drawerTheme, matches } = props;

  let boxStyles: any = styles.sampleNextArrowStyles(className, drawerTheme);

  if (props.size === 'small') {
    boxStyles = {
      ...boxStyles,
      transform: 'translate(0, calc(-50%))',
    };
  }
  if (matches) {
    boxStyles = {
      ...boxStyles,
      width: 50,
    };
  }

  return (
    <Box boxStyles={boxStyles}>
      <Button
        type="link"
        size="large"
        shape="circle"
        onClick={onClick}
        disabled={className.includes('slick-disabled')}
        icon={
          <Text style={{ display: 'flex' }}>
            <RightOutlined
              style={{ color: drawerTheme === 'dark' ? 'white' : void 0 }}
            />
          </Text>
        }
      />
    </Box>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick, className, drawerTheme, matches } = props;

  let boxStyles: any = styles.samplePrevArrowStyles(className, drawerTheme);

  if (props.size === 'small') {
    boxStyles = {
      ...boxStyles,
      transform: 'translate(-1.6rem, calc(-50%)) rotate(180deg)',
    };
  }

  if (matches) {
    boxStyles = {
      ...boxStyles,
      width: 50,
    };
  }

  return (
    <Box boxStyles={boxStyles}>
      <Button
        type="link"
        size="large"
        shape="circle"
        onClick={onClick}
        disabled={className.includes('slick-disabled')}
        icon={
          <Text style={{ display: 'flex' }}>
            <RightOutlined
              style={{ color: drawerTheme === 'dark' ? 'white' : void 0 }}
            />
          </Text>
        }
      />
    </Box>
  );
}

const StyledCarousel = styled(AntdCarousel)(() => carouselStyles);

export function Carousel({
  size,
  children,
}: {
  size?: 'small';
  children: React.ReactNode;
}) {
  const matches = useBreakpoint(991);
  const drawerTheme = useSelector(selectDrawerTheme);

  return (
    <StyledCarousel
      arrows
      dots={false}
      variableWidth
      slidesToShow={2}
      infinite={false}
      swipeToSlide={true}
      waitForAnimate={false}
      nextArrow={
        <SampleNextArrow
          size={size}
          matches={matches}
          drawerTheme={drawerTheme}
        />
      }
      prevArrow={
        <SamplePrevArrow
          size={size}
          matches={matches}
          drawerTheme={drawerTheme}
        />
      }
    >
      {children}
    </StyledCarousel>
  );
}
